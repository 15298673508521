import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';

import classNames from 'classnames';
import { useTableLoading } from '@/hooks/table/use-table-loading';
import { createCompanyProfileLink } from '@/pages/company-profile';
import { useCaseCodePopupConditionally } from '@/Components/Dialogs/CaseCodeDialog/use-show-case-code-popup-conditionally';
import { IconSimilarCompany } from '@/Components/Icons/IconSimilarCompany';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

export const CompanyCell = ({ row, value }: GridRenderCellParams) => {
  const isLoading = useTableLoading();
  const customTableDensity = useShallowSelector((state) => state.table.customTableDensity);
  const { shouldShowCaseCodePopup, handleCaseCodeModalOpen } = useCaseCodePopupConditionally();

  const openCompanyProfile = () => window.open(createCompanyProfileLink(row.bain_id), '_blank');

  const handleClick = () => {
    if (shouldShowCaseCodePopup) {
      handleCaseCodeModalOpen({ onSuccess: openCompanyProfile });

      return;
    }

    openCompanyProfile();
  };

  const logo = row.self_logourl;

  return isLoading ? (
    <Skeleton className="w-full h-full bg-[#0000000f]" />
  ) : (
    <Tooltip
      title="View company profile"
      enterDelay={200}
      enterNextDelay={200}
    >
      <button
        id="open-company-profile"
        className="w-full h-full min-w-0 normal-case text-[#484848] font-normal pl-0 pr-2.5 py-2 text-left truncate group flex gap-6 items-center"
        onClick={handleClick}
      >
        <span
          className={classNames('border border-[#ddd] p-1 inline-block rounded shrink-0', {
            'w-[20px] h-[20px]': customTableDensity === 'compact',
            'w-[28px] h-[28px]': customTableDensity === 'standard',
            'w-[40px] h-[40px]': customTableDensity === 'comfortable',
          })}
        >
          {logo ? (
            <div
              className="w-full h-full bg-no-repeat bg-contain bg-center"
              style={{ backgroundImage: `url(${logo})` }}
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <IconSimilarCompany />
            </div>
          )}
        </span>

        <Tooltip title={value?.length > 40 ? value : ''}>
          <span
            id={value}
            className="text-[#2E3F4C] text-sm font-medium group-hover:underline group-hover:text-[#2F5574] truncate"
          >
            {value}
          </span>
        </Tooltip>
      </button>
    </Tooltip>
  );
};
