import React from 'react';
import { useDispatch } from 'react-redux';
import { GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid-pro';
import Tooltip from '@mui/material/Tooltip';

import { IconButton, Skeleton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ConfidenceScoresSwitch } from '../../Switch/ConfidenceScoresSwitch';
import { actions } from '@/slices/table';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

interface CustomToolbarProps {
  onResetTableSettings: () => void;
  countText: string;
  isLoading: boolean;
  showColumnsVisibilityPanelButton: React.ReactElement;
}

export const CustomToolbar = ({
  onResetTableSettings,
  countText,
  isLoading,
  showColumnsVisibilityPanelButton,
}: CustomToolbarProps) => {
  const dispatch = useDispatch();
  const isChecked = useShallowSelector((state) => state.table.isConfidenceLevelChecked);

  const handleSwitch = () => {
    dispatch(actions.toggleConfidenceLevel());
  };

  const countNumber = Number(countText.replace(/[~,]/g, ''));
  const getCounterLabel = () => {
    if (countNumber === 0) return '';

    return countNumber === 1 ? 'company' : 'companies';
  };

  return (
    <GridToolbarContainer className="pl-4 pr-6 py-0 bg-white rounded-t-lg flex justify-between items-center border-b border-b-[#DDDDDD] min-h-[60px]">
      {isLoading ? (
        <Skeleton
          variant="rounded"
          className="bg-[#0000000f] w-[200px] h-6"
        />
      ) : (
        <div
          data-testid="table-search-meta"
          className="text-[#484848] flex flex-1 justify-start items-end gap-1"
        >
          <span className="font-medium text-xl">
            {countText} {getCounterLabel()}
          </span>
        </div>
      )}

      <div className="flex justify-end items-center gap-4">
        <ConfidenceScoresSwitch
          onToggle={handleSwitch}
          isChecked={isChecked}
        />

        <Tooltip title="Row height">
          <GridToolbarDensitySelector className="min-w-[40px] h-[40px] m-0 toolbar-button rounded-full [&>span]:m-0" />
        </Tooltip>

        <Tooltip title="Reset table">
          <IconButton
            onClick={onResetTableSettings}
            className="hover:bg-[#3f51b50a] "
          >
            <RefreshIcon className="fill-[#666]" />
          </IconButton>
        </Tooltip>

        {showColumnsVisibilityPanelButton}
      </div>
    </GridToolbarContainer>
  );
};
