import React, { MutableRefObject, memo } from 'react';
import { DataGridPro, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { isEqual, pick } from 'lodash';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import './index.scss';
import { useCustomTableTransformation } from '../../../hooks/table/use-custom-table-transformation';
import { CustomToolbar } from './CustomToolbar';
import { Pagination } from './Pagination';
import { NoRowsOverlay } from './NoRowsOverlay';
import { useShowColumnsVisibilityPanelButton } from './CustomToolbar/useShowColumnsVisibilityPanelButton';
import ColumnsVisibilityPanel from '@/Components/CompanyResearch/ColumnsVisibilityPanel';
import { IconGridDensity } from '@/Components/Icons/IconGridDensity';
import { IconTableColumns } from '@/Components/Icons/IconTableColumns';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { IElasticTableData } from '@/types';

interface IProps {
  isLoading: boolean;
  columns: GridColDef[];
  rows: IElasticTableData[];
  countText: string;
  apiRef: MutableRefObject<GridApiPro>;
}

export const TableGridUI = ({ apiRef, isLoading, columns, rows, countText }: IProps) => {
  const pageSize = useShallowSelector((state) => state.table.pageSize);
  const pageNumber = useShallowSelector((state) => state.tableNotPersist.pageNumber);
  const { columnsVisibilityPanelAnchorEl, showColumnsVisibilityPanelButton } =
    useShowColumnsVisibilityPanelButton(apiRef);

  const {
    transformedColumns,
    customDensity,
    muiTableKey,
    customTableFilters,
    columnVisibilityModel,
    onColumnOrderChange,
    onColumnVisibilityModelChange,
    onColumnWidthChange,
    onResetColumnSettings,
    onFilterModelChange,
    onStateChange,
    onSortModelChange,
  } = useCustomTableTransformation({ columns });

  return (
    <DataGridPro
      apiRef={apiRef}
      rowCount={rows.length}
      key={muiTableKey}
      rows={rows}
      filterMode="server"
      columns={isLoading ? columns : transformedColumns}
      filterModel={{ items: customTableFilters }}
      paginationModel={{ page: pageNumber, pageSize }}
      checkboxSelection
      checkboxSelectionVisibleOnly
      autoHeight={false}
      columnHeaderHeight={31}
      rowHeight={46.5}
      disableRowSelectionOnClick
      pagination
      slotProps={{
        toolbar: {
          countText,
          onResetTableSettings: onResetColumnSettings,
          isLoading,
          showColumnsVisibilityPanelButton,
        },
        panel: {
          anchorEl: columnsVisibilityPanelAnchorEl,
          placement: 'bottom-end',
        },
      }}
      localeText={{
        toolbarColumns: '',
        toolbarDensity: '',
      }}
      slots={{
        pagination: Pagination,
        columnSelectorIcon: IconTableColumns,
        densityComfortableIcon: IconGridDensity,
        densityStandardIcon: IconGridDensity,
        densityCompactIcon: IconGridDensity,
        toolbar: CustomToolbar,
        noRowsOverlay: NoRowsOverlay,
        columnsPanel: ColumnsVisibilityPanel,
      }}
      classes={{
        cell: 'relative',
        footerContainer: 'border-t justify-start',
        root: 'rounded-lg shadow-[0_1px_4px_0px_rgba(46,48,48,0.14)]',
      }}
      disableColumnSelector={false}
      paginationMode="server"
      pageSizeOptions={[]}
      sortingMode="server"
      onSortModelChange={onSortModelChange}
      onColumnOrderChange={onColumnOrderChange}
      onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) => onColumnVisibilityModelChange(newModel)}
      columnVisibilityModel={columnVisibilityModel}
      onColumnWidthChange={onColumnWidthChange}
      density={customDensity}
      onStateChange={onStateChange}
      onFilterModelChange={onFilterModelChange}
      initialState={{ pinnedColumns: { left: ['__check__', 'self_firmo_name___'] } }}
      disableColumnMenu
    />
  );
};

export const TableGrid = memo(TableGridUI, (prev, next) => {
  return isEqual(
    pick(prev, ['rows', 'totalNumRows', 'isNumRowsLoading', 'countText']),
    pick(next, ['rows', 'totalNumRows', 'isNumRowsLoading', 'countText']),
  );
});
