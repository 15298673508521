import { get, post } from 'aws-amplify/api';

import { ChartDataPayload, ChartConfigType, ChartDataType, ChartFilterResponse } from '@/types';

export const fetchChartConfigs = (): Promise<ChartConfigType[]> =>
  // @ts-ignore
  get({ apiName: 'CDPAPI', path: '/charts' }).response.then(res => res.body.json()).then((res) => res.charts);

export const fetchChartData = ({
  chartId,
  bainId,
  currency,
  dataSource,
}: ChartDataPayload): Promise<ChartDataType[]> => {
  const params = new URLSearchParams({
    bain_id: bainId,
    currency: currency,
    ...(dataSource && {
      data_source: dataSource,
    }),
  });

  // @ts-ignore
  return get({ apiName: 'CDPAPI', path: `/charts/${chartId}?${params.toString()}` }).response.then(res => res.body.json()).then((res) => res.data);
};

export const fetchFilteredChartData = ({
  chartId,
  bainId,
  currency,
  dataSource,
  body,
}: ChartDataPayload): Promise<ChartDataType[]> => {
  const params = new URLSearchParams({
    bain_id: bainId,
    currency: currency,
    ...(dataSource && {
      data_source: dataSource,
    }),
  });

  // @ts-ignore
  return post({ apiName: 'CDPAPI', path: `/charts/${chartId}?${params.toString()}`, options: { body } }).response.then(res => res.body.json()).then((res) => res.data);
};

export const fetchChartFilters = (
  chartId: number,
  bain_id: string,
): Promise<ChartFilterResponse> => {
  // @ts-ignore
  return get({ apiName: 'CDPAPI', path: `/charts/${chartId}/filters?bain_id=${bain_id}` }).response.then(res => res.body.json());
};
