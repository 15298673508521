import React, { FC, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { reduce } from 'lodash';
import { SkeletonLoader } from '../../common/SkeletonLoader';
import { GenericTable } from './GenericTable';
import { getDataSource, useGenericTableDataSource } from './use-data-source';
import { useQueryCompanyProfileDataset } from '@/hooks/queries/company-profile/use-query-company-profile-dataset';
import { ucFirstLetter } from '@/Utils/text';
import { useQueryColumnMapperDataset } from '@/hooks/queries/column-mapper/use-query-column-mapper-dataset';
import { getColumns } from '@/Components/Shared/ScreenerTable/columns';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getRows } from '@/Utils/table';
import { IElasticTableData } from '@/types';
import { DataSourceMenu } from '@/Components/Shared/DataSourceMenu';

export interface GenericTableTabProps {
  bainId: string;
  dataset: string;
  tabSlug: string;
  title?: string;
  disallowDownload?: boolean;
}

export const GenericTableTab: FC<GenericTableTabProps> = ({ bainId, dataset, title, disallowDownload, tabSlug }) => {
  const currency = useShallowSelector((state) => state.config.currency);
  const { data: rawData, isLoading: isDataLoading } = useQueryCompanyProfileDataset({ bainId, dataset });
  const { data: datasetColumnMapper, isLoading: isDatasetColumnMapperLoading } = useQueryColumnMapperDataset(dataset);

  const isLoading = isDataLoading || isDatasetColumnMapperLoading;
  const data = rawData?.map((value, index) => ({ id: index, ...value })) as IElasticTableData[] | undefined;
  const tableRows = useMemo(() => data ?? [], [data]);
  const columnMapper = useMemo(() => datasetColumnMapper ?? [], [datasetColumnMapper]);
  const { currentDataSource, dataSourcesAvailable, handleDataSourceChange, preferredDataSource } =
    useGenericTableDataSource(tableRows, tabSlug);

  const filteredTableRows = useMemo(() => {
    if (!currentDataSource) return tableRows;

    return tableRows.filter((row) => getDataSource(row) === currentDataSource);
  }, [currentDataSource, tableRows]);

  const columns = useMemo(
    () =>
      getColumns({
        isFluid: true,
        columnMapper,
        currency,
        defaultColumns: columnMapper
          .filter((column) => column['DEFAULT_COLUMN'])
          .map((column) => column['Backend Name']),
      }),
    [columnMapper, currency],
  );

  const rows = useMemo(
    () => getRows({ rows: filteredTableRows, isLoading, placeholderRows: 5 }),
    [filteredTableRows, isLoading],
  );
  const sortColumn = columns.find((column) => column.sortable);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  if (!data || !datasetColumnMapper) {
    return <div>No data for {dataset}. Please contact system administrator.</div>;
  }

  if (!bainId || !dataset) {
    return null;
  }

  const columnVisibilityModel = reduce(
    datasetColumnMapper,
    (acc, column) => ({ ...acc, [column['Backend Name']]: column.DEFAULT_COLUMN }),
    {},
  );

  return (
    <>
      {currentDataSource &&
        createPortal(
          <DataSourceMenu
            currentDataSource={currentDataSource}
            preferredDataSource={preferredDataSource}
            dataSourcesAvailable={dataSourcesAvailable}
            inputLabelId="fintab-data-source-label"
            selectLabelId="fintab-data-source-label"
            selectId="fintab-data-source"
            onChange={handleDataSourceChange}
          />,
          document.getElementById('data-source') as HTMLElement,
        )}

      <GenericTable
        tableName={title ?? ucFirstLetter(dataset.replaceAll('_', ' '))}
        columns={columns}
        rows={rows}
        sortModel={sortColumn ? [{ field: sortColumn.field, sort: 'desc' }] : []}
        disallowDownload={disallowDownload}
        initialColumnVisibilityModel={columnVisibilityModel}
      />
    </>
  );
};
