import React from 'react';
import classnames from 'classnames';
import { CustomButton } from './CustomButton';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { MODALS } from '@/constants';

import { useModal } from '@/hooks/use-modal';

export const AdvancedSearchButton = () => {
  const { handleOpen: openAdvancedSearchDialog } = useModal(MODALS.ADVANCED_SEARCH);
  const customAggregationString = useShallowSelector((state) => state.filters.customAggregationString);

  const isAdvancedMode = !!customAggregationString;

  return (
    <CustomButton
      onClick={() => openAdvancedSearchDialog()}
      data-testid="advanced-button"
      className={classnames({
        'bg-[#fff] text-bluegray-900': !isAdvancedMode,
        'bg-bluegray-900 text-white': isAdvancedMode,
      })}
    >
      Advanced
    </CustomButton>
  );
};
