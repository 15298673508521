import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { GroupedMatchSearchResults } from './GroupedMatchSearchResults';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { WorkflowDialogFooter } from '@/Components/Workflows/Common/WorkflowDialogFooter';
import { HOME_PAGE_SLUG } from '@/constants';
import { IconSearch } from '@/Components/Icons/IconSearch';
import { useMutationGroupedMatchCompanySearch, useMutationGroupedRankCompanySearch } from '@/hooks/queries';

const FORM_STATE = {
  searchText: '',
  industryText: '',
};

type FormState = typeof FORM_STATE;

const CompanyFinder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const [isFetched, setIsFetched] = useState(false);
  const [isRankSearch, setIsRankSearch] = useState(false);
  const [searchedText, setSearchedText] = useState<string>('');
  const { control, handleSubmit, watch } = useForm({
    defaultValues: FORM_STATE,
  });
  const searchText = watch('searchText');
  const matchSearchMutation = useMutationGroupedMatchCompanySearch();
  const rankSearchMutation = useMutationGroupedRankCompanySearch();
  const data = [...(rankSearchMutation.data ? [rankSearchMutation.data] : []), ...(matchSearchMutation.data ?? [])];
  const error = matchSearchMutation.error || rankSearchMutation.error;

  const onSubmit = (formData: FormState) => {
    if (formData.searchText.length < 3) {
      enqueueSnackbar(<NotificationMessage title="Please provide at least 3 characters" />, {
        variant: 'warning',
      });

      return;
    }

    if (formData.industryText) {
      rankSearchMutation.mutate({ searchText: formData.searchText, industry: formData.industryText });
      setIsRankSearch(true);
    } else {
      rankSearchMutation.reset();
      setIsRankSearch(false);
    }

    matchSearchMutation.mutate({ searchText: formData.searchText });
    setIsFetched(true);
    setSearchedText(formData.searchText);
  };

  return (
    <div className="w-full h-[calc(100vh-52px)] flex flex-col overflow-y-scroll">
      <div className="w-[620px] flex flex-col justify-center mt-12 m-auto">
        <div className="mb-2 text-2xl font-medium text-[#484848]">What company are you looking for?</div>
        <div className="text-sm font-normal text-[#484848]">
          Our company profiles contain detailed financial, workforce & web traffic insights.
        </div>

        <div className="flex flex-col sticky top-0 bg-white z-10 pt-7">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full flex flex-col"
          >
            <Controller
              name="searchText"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  placeholder="Enter company name"
                  InputProps={{
                    startAdornment: (
                      <div className="pr-2">
                        <IconSearch color="#999999" />
                      </div>
                    ),
                  }}
                />
              )}
            />

            <div className="flex items-center my-5">
              <span className="text-[#666666] text-xs mr-4">Add for better results</span>
              <div className="flex-grow h-px bg-[#F5F5F5]"></div>
            </div>

            <Controller
              name="industryText"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  placeholder="Industry"
                />
              )}
            />

            <div className="mt-6">
              <Button
                id="company-finder-button"
                type="submit"
                variant="text"
                className="normal-case text-[14px] bg-[#2e3f4c] px-4 py-2.5 text-white text-sm font-medium"
              >
                Search
              </Button>
            </div>
          </form>

          <div className="h-[1px] bg-[#F5F5F5] mt-3" />
        </div>

        <div className="overflow-hidden">
          <div className="flex-grow">
            <GroupedMatchSearchResults
              data={data}
              error={error}
              searchText={searchedText}
              isMatchSearchLoading={matchSearchMutation.isLoading}
              isRankSearchLoading={rankSearchMutation.isLoading}
              isFetched={isFetched}
              isRankSearch={isRankSearch}
            />
          </div>
        </div>
      </div>

      <WorkflowDialogFooter
        onBackButtonClick={() => push(HOME_PAGE_SLUG)}
        isLoading={false}
        progress={searchText ? 70 : 30}
      />
    </div>
  );
};

export default CompanyFinder;
