import React from 'react';

import { useSnackbar } from 'notistack';

import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';

export const SEMANTIC_DESCRIPTION_MIN_CHARACTERS = 50;
export const SEMANTIC_DESCRIPTION_MAX_CHARACTERS = 2000;

export const toastMinErrorMessage = `Oops. Please provide at least ${SEMANTIC_DESCRIPTION_MIN_CHARACTERS} characters and focus on what the company does, avoiding excessive negatives. This will help us deliver more accurate results. Try again`;
export const inlineMinErrorMessage = `Provide at least ${SEMANTIC_DESCRIPTION_MIN_CHARACTERS} characters. Focus on what the company does, avoiding excessive negatives. This will deliver more accurate results.`;
export const toastMaxErrorMessage = `Oops. Please provide maximum ${SEMANTIC_DESCRIPTION_MAX_CHARACTERS} characters and focus on what the company does, avoiding excessive negatives. This will help us deliver more accurate results. Try again`;
export const inlineMaxErrorMessage = `Provide maximum ${SEMANTIC_DESCRIPTION_MAX_CHARACTERS} characters. Focus on what the company does, avoiding excessive negatives. This will deliver more accurate results.`;

export const useSemanticSearchValueValidation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const validate = (value?: string, skipToast?: boolean) => {
    const trimmedValue = value?.trim();

    if (!trimmedValue) {
      if (!skipToast) {
        enqueueSnackbar(<NotificationMessage title={toastMinErrorMessage} />, { variant: 'error' });
      }

      return { validated: false, errorMessage: inlineMinErrorMessage };
    }

    const numberOfCharacters = trimmedValue.length;

    if (numberOfCharacters < SEMANTIC_DESCRIPTION_MIN_CHARACTERS) {
      if (!skipToast) {
        enqueueSnackbar(<NotificationMessage title={toastMinErrorMessage} />, { variant: 'error' });
      }

      return { validated: false, errorMessage: inlineMinErrorMessage };
    }

    if (numberOfCharacters > SEMANTIC_DESCRIPTION_MAX_CHARACTERS) {
      if (!skipToast) {
        enqueueSnackbar(<NotificationMessage title={toastMaxErrorMessage} />, { variant: 'error' });
      }

      return {
        validated: false,
        errorMessage: inlineMaxErrorMessage,
      };
    }

    return {
      validated: true,
      errorMessage: '',
    };
  };

  return { validate };
};
