import React from 'react';
import { Divider } from '@mui/material';
import { isNil } from 'lodash';
import { IconSimilarCompany } from '@/Components/Icons/IconSimilarCompany';
import { createCompanyProfileLink } from '@/pages/company-profile';
import { IconLocation } from '@/Components/Icons';
import { getCurrencyFormat } from '@/Components/Shared/common';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

export interface GroupedMatchSearchItemCardProps {
  name: string;
  bainId: string;
  country?: string;
  industry?: string;
  revenue?: number;
  logoUrl?: string;
}

export const GroupedMatchSearchItemCard = ({
  bainId,
  name,
  country,
  industry,
  revenue,
  logoUrl,
}: GroupedMatchSearchItemCardProps) => {
  const handleOpenCompanyProfile = () => window.open(createCompanyProfileLink(bainId), '_blank');
  const currency = useShallowSelector((state) => state.config.currency);

  return (
    <button
      className="p-4 w-full flex gap-4 group/title"
      onClick={handleOpenCompanyProfile}
    >
      <span className="border border-[#ddd] p-1 rounded">
        {logoUrl ? (
          <div
            className="w-[40px] h-[40px] bg-no-repeat bg-contain bg-center"
            style={{ backgroundImage: `url(${logoUrl})` }}
            data-testid={`${bainId}-logo-available`}
          />
        ) : (
          <div
            className="w-[40px] h-[40px] flex items-center justify-center"
            data-testid={`${bainId}-logo-unavailable`}
          >
            <IconSimilarCompany />
          </div>
        )}
      </span>
      <div className="w-full flex flex-col items-start gap-2">
        <p className="w-full text-left font-medium text-[#484848] group-hover/title:underline">{name}</p>
        <div className="flex gap-2 text-sm text-[#666] font-normal w-full flex-wrap">
          {country ? (
            <span className="flex gap-2 items-center ">
              <IconLocation />
              {country}
            </span>
          ) : null}
          {industry ? (
            <span className="flex gap-2 items-center ">
              <Divider
                className="text-[#ddd]"
                orientation="vertical"
                variant="middle"
              />
              {industry}
            </span>
          ) : null}

          {!isNil(revenue) ? (
            <span className="flex gap-2 items-center ">
              <Divider
                className="text-[#ddd]"
                orientation="vertical"
                variant="middle"
              />
              {getCurrencyFormat(revenue, currency)}
            </span>
          ) : null}
        </div>
      </div>
    </button>
  );
};
