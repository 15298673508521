import React from 'react';

import { IconLoader } from '@/Components/Icons/IconLoader';

export const Loader = () => (
  <IconLoader
    className="animate-[spin_1.5s_linear_infinite]"
    data-testid="icon-loader"
  />
);
