import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual, isNull } from 'lodash';
import classNames from 'classnames';
import { actions as searchActions } from '../../../../slices/search';
import { actions as filterActions } from '../../../../slices/filters';
import { SEMANTIC_SEARCH_CHECKBOXES, SemanticSearchNarrowingDescription } from './SemanticSearchNarrowingDescription';
import { IconInfo } from '@/Components/Icons/IconInfo';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { IconClear } from '@/Components/Icons/IconClear';

export const SemanticSearchNarrowingBox = () => {
  const dispatch = useDispatch();

  const { semanticSearch: semanticSearchState, searchQuery: storeSearchQuery } = useShallowSelector(
    (state) => state.search,
  );
  const storeFilters = useShallowSelector((state) => state.filters);
  const descriptionChecked = semanticSearchState?.applyDescription;

  const allAiResponseFilters = {
    ...semanticSearchState?.aiResponse?.filters.treeFilters,
    ...semanticSearchState?.aiResponse?.filters.otherFilters,
  };

  const allStoreFilters = {
    ...storeFilters.treeFilters,
    ...storeFilters.otherFilters,
  };

  const areAiResponseFiltersEmpty = isEmpty(allAiResponseFilters);
  const areAiResponseKeywordsEmpty = isEmpty(semanticSearchState?.aiResponse?.keywords);
  const areStoreFiltersEmpty = isEmpty(allStoreFilters);
  const areStoreKeywordsEmpty = isEmpty(storeSearchQuery);

  const filtersChecked =
    isEqual(storeFilters.treeFilters, semanticSearchState?.aiResponse?.filters.treeFilters) &&
    isEqual(storeFilters.otherFilters, semanticSearchState?.aiResponse?.filters.otherFilters) &&
    !areAiResponseFiltersEmpty;

  const areKeywordEqual = isEqual(storeSearchQuery, semanticSearchState?.aiResponse?.keywords);

  const keywordsChecked = areKeywordEqual && !areAiResponseKeywordsEmpty;

  const onDescriptionCheckboxChange = (checked: boolean) => {
    if (!semanticSearchState) return;

    dispatch(searchActions.setSemanticSearch({ ...semanticSearchState, applyDescription: checked }));
    dispatch(searchActions.setIsElasticEnabled(true));
  };

  const onFiltersCheckboxChange = (checked: boolean) => {
    if (!semanticSearchState || areAiResponseFiltersEmpty) return;

    if (checked) {
      dispatch(
        filterActions.setFilters({
          ...storeFilters,
          treeFilters: semanticSearchState?.aiResponse?.filters.treeFilters ?? {},
          otherFilters: semanticSearchState?.aiResponse?.filters.otherFilters ?? {},
        }),
      );
      dispatch(searchActions.setIsElasticEnabled(true));

      return;
    }

    dispatch(
      filterActions.setFilters({
        ...storeFilters,
        treeFilters: {},
        otherFilters: {},
        customAggregationString: undefined,
      }),
    );
    dispatch(searchActions.setIsElasticEnabled(true));
  };

  const onKeywordCheckboxChange = (checked: boolean) => {
    if (!semanticSearchState) return;

    if (checked) {
      dispatch(searchActions.setSearchQuery(semanticSearchState?.aiResponse?.keywords ?? ''));

      dispatch(searchActions.setIsElasticEnabled(true));

      return;
    }

    dispatch(searchActions.setSearchQuery(''));
    dispatch(searchActions.setIsElasticEnabled(true));
  };

  const areFiltersChanged = useMemo(() => {
    if (filtersChecked) return false;

    if (areAiResponseFiltersEmpty) return false;

    if (!areAiResponseFiltersEmpty && areStoreFiltersEmpty) return false;

    return (
      !isEqual(storeFilters.treeFilters, semanticSearchState?.aiResponse?.filters.treeFilters) ||
      !isEqual(storeFilters.otherFilters, semanticSearchState?.aiResponse?.filters.otherFilters)
    );
  }, [
    filtersChecked,
    areAiResponseFiltersEmpty,
    areStoreFiltersEmpty,
    semanticSearchState?.aiResponse?.filters.otherFilters,
    semanticSearchState?.aiResponse?.filters.treeFilters,
    storeFilters.otherFilters,
    storeFilters.treeFilters,
  ]);

  const areKeywordsChanged = useMemo(() => {
    if (keywordsChecked) return false;

    if (areAiResponseKeywordsEmpty) return false;

    if (!areAiResponseKeywordsEmpty && areStoreKeywordsEmpty) return false;

    return !areKeywordEqual;
  }, [areAiResponseKeywordsEmpty, areKeywordEqual, areStoreKeywordsEmpty, keywordsChecked]);

  const checkedCheckboxes = [
    descriptionChecked ? SEMANTIC_SEARCH_CHECKBOXES?.description : null,
    filtersChecked || areFiltersChanged ? SEMANTIC_SEARCH_CHECKBOXES?.filters : null,
    keywordsChecked || areKeywordsChanged ? SEMANTIC_SEARCH_CHECKBOXES?.keywords : null,
  ].filter((checkbox) => !isNull(checkbox));

  const isSemanticNull = isNull(semanticSearchState);

  return (
    <div className="bg-white border border-[#DDDDDD] px-5 py-4 rounded-md shadow-[0px_2px_4px_0px_#0000001A] min-w-[660px]">
      <div className="flex justify-between mb-4">
        <span className="text-[10px] font-medium text-[#666]">FIND COMPANIES THAT MATCH</span>
        <Tooltip title="Toggle these areas to adjust what you want to include in your search. Selecting them all will look for matches on Description and Industry and Keywords. The more you include the smaller your result set.">
          <span>
            <IconInfo
              className="w-4"
              fill="#231F20"
            />
          </span>
        </Tooltip>
      </div>
      <div className="flex justify-between items-center gap-8">
        <div>
          <FormControlLabel
            key={String(descriptionChecked)}
            control={
              <Checkbox
                color="info"
                sx={{
                  color: '#DDD',
                }}
                checked={descriptionChecked}
                disableRipple
                disableFocusRipple
              />
            }
            onChange={(_, checked) => onDescriptionCheckboxChange(checked)}
            label="Description"
            classes={{
              root: classNames('flex', {
                ['opacity-40']: isSemanticNull,
              }),
              label: 'text-[#484848]',
            }}
            value={descriptionChecked}
            disabled={isSemanticNull}
          />
          <Tooltip title={areFiltersChanged ? 'Back to AI generated Industries' : ''}>
            <FormControlLabel
              control={
                areFiltersChanged ? (
                  <div
                    className="ml-[10px] mr-3 my-[11px]"
                    data-testid="filters-changed-indicator"
                  >
                    <IconClear fill="#484848" />
                  </div>
                ) : (
                  <Checkbox
                    color="info"
                    sx={{
                      color: '#DDD',
                    }}
                    checked={filtersChecked}
                    disableRipple
                    disableFocusRipple
                  />
                )
              }
              onChange={(_, checked) => onFiltersCheckboxChange(checked)}
              onClick={areFiltersChanged ? () => onFiltersCheckboxChange(false) : undefined}
              label="Industries"
              classes={{
                root: classNames('flex', {
                  ['opacity-40']: areAiResponseFiltersEmpty,
                }),
                label: 'text-[#484848]',
              }}
              value={filtersChecked}
              disabled={areAiResponseFiltersEmpty}
            />
          </Tooltip>
          <Tooltip title={areKeywordsChanged ? 'Back to AI generated Keywords' : ''}>
            <FormControlLabel
              control={
                areKeywordsChanged ? (
                  <div
                    className="ml-[10px] mr-3 my-[11px]"
                    data-testid="keywords-changed-indicator"
                  >
                    <IconClear fill="#484848" />
                  </div>
                ) : (
                  <Checkbox
                    color="info"
                    sx={{
                      color: '#DDD',
                    }}
                    checked={keywordsChecked}
                    disableRipple
                    disableFocusRipple
                  />
                )
              }
              onChange={(_, checked) => onKeywordCheckboxChange(checked)}
              onClick={areKeywordsChanged ? () => onKeywordCheckboxChange(false) : undefined}
              label="Keywords"
              classes={{
                root: classNames('flex', {
                  ['opacity-40']: areAiResponseKeywordsEmpty,
                }),
                label: 'text-[#484848]',
              }}
              value={keywordsChecked}
              disabled={areAiResponseKeywordsEmpty}
            />
          </Tooltip>
        </div>
        <SemanticSearchNarrowingDescription checkedCheckboxes={checkedCheckboxes} />
      </div>
    </div>
  );
};
