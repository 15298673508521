import React from 'react';
import cn from 'classnames';

import { ProfileHeaderIndustryItem } from '../HeaderIndustryItem';
import { DatumDatum } from '@/types';

interface IProps {
  row?: DatumDatum;
}

export const HeaderChips = (props: IProps) => {
  const companyType = props?.row?.self_firm_typeL1___;
  const headcountTrend = props?.row?.SELF_PEOPLE_HEADCOUNTTREND___ ?? props?.row?.self_people_HeadcountTrend___;
  const founderLed = props?.row?.self_execs_FoundersLed;

  return (
    <div className="flex items-center gap-3 text-[#484848]">
      {companyType && (
        <div className="flex items-center gap-1 bg-[#ebe6ff] text-sm font-medium rounded p-1">
          <span>{companyType}</span>
        </div>
      )}

      <ProfileHeaderIndustryItem row={props.row} />

      {!!headcountTrend && headcountTrend !== 'NA' && (
        <div
          className={cn('flex items-center gap-1 text-sm font-medium rounded p-1', {
            'bg-[#ebf9f2]': headcountTrend === 'Growing',
            'bg-[#fef9dc]': headcountTrend === 'Stable',
            'bg-[#fce3e3]': headcountTrend === 'Declining',
          })}
        >
          <span className="">{headcountTrend}</span>
        </div>
      )}

      {founderLed === 'Y' && (
        <div className="flex items-center gap-1 bg-[#e6fcff] text-sm font-medium rounded p-1">
          <span className="">Founder led</span>
        </div>
      )}
    </div>
  );
};
