import React, { FC, useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { reduce } from 'lodash';
import { GenericTableTabProps } from '../GenericTableTab/GenericTableTab';
import { SkeletonLoader } from '../../common/SkeletonLoader';
import { GenericTable } from '../GenericTableTab/GenericTable';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useQueryCompanyProfileDataset } from '@/hooks/queries/company-profile/use-query-company-profile-dataset';
import { ucFirstLetter } from '@/Utils/text';
import { useQueryColumnMapperDataset } from '@/hooks/queries/column-mapper/use-query-column-mapper-dataset';
import { getColumns, getRowBasedColumn } from '@/Components/Shared/ScreenerTable/columns';
import { pivotTable } from '@/Utils/pivot';

export interface PivotTableTabProps extends Omit<GenericTableTabProps, 'tabSlug'> {
  pivotColumn: string;
  valueColumn: string;
  aggregateFunction?: (...values: number[]) => number;
}

export const PivotTableTab: FC<PivotTableTabProps> = ({
  bainId,
  dataset,
  title,
  disallowDownload,
  pivotColumn,
  valueColumn,
}) => {
  const currency = useShallowSelector((state) => state.config.currency);
  const { data: rawData, isLoading: isDataLoading } = useQueryCompanyProfileDataset({ bainId, dataset });
  const { data: columnMapper, isLoading: isColumnMapperLoading } = useQueryColumnMapperDataset(dataset);
  const isLoading = isDataLoading || isColumnMapperLoading;

  const numericPrecision =
    columnMapper?.find((column) => column['Backend Name'] === valueColumn)?.NUMERIC_PRECISION ?? null;

  const pivotData = useMemo(
    () => pivotTable(rawData ?? [], pivotColumn, valueColumn, numericPrecision),
    [rawData, pivotColumn, valueColumn, numericPrecision],
  );

  const baseColumns = useMemo(() => {
    if (!columnMapper) return [];

    return getColumns({
      columnMapper: columnMapper.filter(
        (column) => column['Backend Name'] !== pivotColumn && column['Backend Name'] !== valueColumn,
      ),
      currency: currency,
      defaultColumns: columnMapper.filter((column) => column['DEFAULT_COLUMN']).map((column) => column['Backend Name']),
    });
  }, [columnMapper, currency, pivotColumn, valueColumn]);

  const pivotColumns: GridColDef[] = useMemo(
    () =>
      Array.from(new Set(pivotData.map(Object.keys).flat()))
        .filter((field) => !baseColumns.find((column) => column.field == field))
        .sort()
        .map((field) => getRowBasedColumn({ field, displayName: field, currency: currency, flex: 1, minWidth: 150 })),
    [pivotData, baseColumns, currency],
  );

  if (isLoading) {
    return <SkeletonLoader />;
  }

  if (!pivotData || !columnMapper) {
    return <div>No data for {dataset}. Please contact system administrator.</div>;
  }

  if (!bainId || !dataset) {
    return null;
  }

  const columns = baseColumns.concat(pivotColumns);

  const columnVisibilityModel = reduce(
    columnMapper,
    (acc, column) => ({ ...acc, [column['Backend Name']]: column.DEFAULT_COLUMN }),
    {},
  );

  return (
    <GenericTable
      tableName={title ?? ucFirstLetter(dataset.replaceAll('_', ' '))}
      columns={columns}
      rows={pivotData.map((r, i) => ({ ...r, id: i, units: '#' }))}
      disallowDownload={disallowDownload}
      pinnedColumns={{ left: baseColumns.map((column) => column.field) }}
      initialColumnVisibilityModel={columnVisibilityModel}
    />
  );
};
