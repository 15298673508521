import { useMemo, useEffect, useRef, useCallback } from 'react';
import { uniqBy } from 'lodash';
import { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import { IElasticTableData } from '@/types';
import { actions } from '@/slices/company-profile';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

const PREFERRED_DATA_SOURCES = ['Crunchbase', 'GainPro'];

export const getDataSource = (row: IElasticTableData) => row?.DATA_SOURCE ?? row?.data_source;

export const useGenericTableDataSource = (tableRows: IElasticTableData[], tabSlug: string) => {
  const dispatch = useDispatch();
  const currentDataSource = useShallowSelector((state) => state.companyProfile.genericTabDataSource?.[tabSlug]);

  const hasInitialValue = useRef(false);

  const dataSourcesAvailable = useMemo(
    () => (tableRows ? uniqBy(tableRows, (row) => getDataSource(row)).map((row) => getDataSource(row)) : []),
    [tableRows],
  );

  const setCurrentDataSource = useCallback(
    (dataSource: string | null) => {
      dispatch(actions.setGenericTabDataSource({ tabSlug, dataSource }));
    },
    [dispatch, tabSlug],
  );

  const preferredDataSource = PREFERRED_DATA_SOURCES.find((dataSource) => dataSourcesAvailable.includes(dataSource));

  useEffect(() => {
    if (hasInitialValue.current || !dataSourcesAvailable.length) return;

    hasInitialValue.current = true;

    if (preferredDataSource) {
      setCurrentDataSource(preferredDataSource);

      return;
    }

    setCurrentDataSource(dataSourcesAvailable.length ? (dataSourcesAvailable[0] as string) : null);
  }, [dataSourcesAvailable, preferredDataSource, setCurrentDataSource]);

  const handleDataSourceChange = (event: SelectChangeEvent<string | null>) => {
    setCurrentDataSource(event.target.value);
  };

  return {
    currentDataSource,
    dataSourcesAvailable: dataSourcesAvailable as string[],
    handleDataSourceChange,
    preferredDataSource,
  };
};
