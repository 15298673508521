import React, { RefObject, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import { isEmpty } from 'lodash';
import 'chart.js/auto';

import { useQueryChartData } from '@/hooks/queries';
import { BarChart, LineChart } from '@/Components/Shared/Charts';
import { ChartConfigType } from '@/types';
import { CompareChartsType } from '@/types/state/charts';
import { actions } from '@/slices/charts';
import { downloadChartExcel, downloadChartImg } from '@/Components/Shared/Charts/utils';

export interface IProps {
  config: ChartConfigType;
  bainId: string;
  section: string;
}

export const ChartTab = ({ config, bainId, section }: IProps) => {
  const dispatch = useDispatch();
  const query = useQueryChartData(config.ID, bainId);
  const chartData = useMemo(() => query.data ?? [], [query.data]);

  const handleDownloadXlsx = useCallback(async () => {
    await downloadChartExcel({
      fileName: `${config.TITLE}-data.xlsx`,
      sheets: [{
        name: 'Data',
        data: chartData,
      }],
    });
  }, [chartData, config.TITLE]);

  const handleRemoveDataset = useCallback((compareData: CompareChartsType) => {
    dispatch(actions.removeCompany(compareData));
  }, [dispatch]);

  const handleDownloadImage = useCallback((ref: RefObject<HTMLDivElement | null>) => {
    downloadChartImg(ref, `${config.TITLE}-chart.png`);
  }, [config.TITLE]);

  if (isEmpty(chartData) && !query.isLoading) {
    return <p>This chart is currently unavailable due to insufficient data.</p>
  }

  return query.isLoading ? (
    <Skeleton
      height={400}
      variant="rounded"
      className="bg-[#0000000f]"
      data-testid="skeleton"
    />
  ) : (
    <div
      className="bg-white rounded"
      style={{ boxShadow: '0px 1px 3px 0px rgba(46, 48, 48, 0.14)' }}
    >
      {config.CHART_TYPE === 'LINE' && (
        <LineChart
          config={config}
          section={section}
          data={{ bainId, data: chartData }}
          comparisonDatasets={[]}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
          handleRemoveDataset={handleRemoveDataset}
        />
      )}

      {config.CHART_TYPE === 'BAR' && (
        <BarChart
          config={config}
          section={section}
          data={{ bainId, data: chartData }}
          comparisonDatasets={[]}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
          handleRemoveDataset={handleRemoveDataset}
        />
      )}
    </div>
  );
};
