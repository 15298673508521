import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useDispatch } from 'react-redux';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useTableLoading } from '@/hooks/table/use-table-loading';
import { actions as selectedCompaniesActions } from '@/slices/selected-companies';

export const CheckboxCell = ({ row }: GridRenderCellParams) => {
  const dispatch = useDispatch();
  const isTableLoading = useTableLoading();
  const selectedCompanies = useShallowSelector((state) => state.selectedCompanies.selectedCompanies);
  const bainId = String(row.bain_id);
  const isChecked = selectedCompanies.includes(bainId);

  const handleCheckboxClick = () => {
    if (!isTableLoading) {
      dispatch(selectedCompaniesActions.toggleSelectedCompany(bainId));
    }
  };

  return (
    <Checkbox
      checked={isChecked}
      disableRipple
      disableFocusRipple
      disabled={isTableLoading}
      sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
      classes={{
        ...(!isChecked && {
          root: 'text-[#231F20] w-full h-full',
        }),
        checked: 'text-[#0288d1] w-full h-full',
      }}
      onClick={handleCheckboxClick}
    />
  );
};
