import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';

import { ChartFilterType } from '@/types';
import { actions } from '@/slices/charts';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

interface IProps {
  chartId: number;
  filterData?: ChartFilterType;
}

export const ChartCategoricalFilter = ({ chartId, filterData }: IProps) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<string[]>([]);
  const chartFilters = useShallowSelector(state => state.charts.filters[chartId]);
  const filterValue = chartFilters?.value;

  useEffect(() => {
    if (filterValue) {
      setSelected(filterValue);
    }
  }, [filterValue]);

  const onSetFilters = () => {
    dispatch(actions.setFilters({
      id: chartId,
      data: {
        type: filterData?.type as string,
        name: filterData?.name as string,
        value: selected,
      },
    }));
  };

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const { target: { value } } = event;
    const result = typeof value === 'string' ? value.split(',') : value;

    setSelected(result);
  };

  return filterData ? (
    <div>
      <Select
        displayEmpty
        size="small"
        labelId="demo-multiple-checkbox-label"
        id={`chart-filter-${filterData.name}`}
        multiple
        value={selected}
        onChange={handleChange}
        onClose={onSetFilters}
        placeholder="All"
        className="h-[32px]min-w-[126px] max-w-[300px]"
        sx={{ width: 'auto' }}
        renderValue={(value) => {
          if (value.length === 0) {
            return <span className="text-[#2e3f4c]">{_.capitalize(filterData.name)}</span>;
          }

          return value.join(', ');
        }}
      >
        {filterData.data.map((name) => (
          <MenuItem key={name} value={name} classes={{ root: 'h-[32px] min-h-[32px] pl-1 pr-4' }}>
            <Checkbox size="small" checked={selected.includes(name)} sx={{ color: '#ddd' }} disableRipple />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </div>
  ) : null;
}
