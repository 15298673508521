import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { isNull, uniq } from 'lodash';

import { ChartConfigType, ChartDatasetType, ChartDataType } from '@/types';

const getChartData = (data: ChartDataType[]) => {
  const from = data.map((item) => item.from);
  const to = data.map((item) => item.to);
  const items = uniq(from.concat(to));

  const getSingleEntryName = () => {
    if (uniq(from).length === 1) return from[0];
    if (uniq(to).length === 1) return to[0];

    return null;
  };

  const singleEntryName = getSingleEntryName();

  if (!isNull(singleEntryName))
    return items.map((item, index, array) => ({
      name: item,
      itemStyle: {
        color: '#2E3F4C',
        opacity: singleEntryName === item ? 1 : 1 - index / (array.length * 1.25),
      },
    }));

  return items.map((item) => ({
    name: item,
  }));
};

interface IProps {
  config: ChartConfigType;
  data: ChartDatasetType;
}

export const SankeyChart = ({ data, config }: IProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let chart: echarts.ECharts;

    if (ref.current) {
      const chartData = getChartData(data.data);
      const chartLinks = data.data.map((item) => ({ source: item.from, target: item.to, value: item.flow }));

      chart = echarts.init(ref.current);

      const option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
        },
        color: ['#B9C5CE', '#96A7B6', '#738A9D', '#506D85', '#2F5574', '#2E3F4C', '#506D85', '#2F5574', '#2E3F4C'],
        series: {
          draggable: false,
          type: 'sankey',
          emphasis: {
            focus: 'adjacency',
          },
          data: chartData,
          links: chartLinks,
          lineStyle: {
            color: 'gradient',
            curveness: 0.5,
          },
        },
      };

      chart?.setOption(option);
    }

    return () => {
      chart?.dispose();
    };
  }, [data]);

  return (
    <div className="px-4">
      <div className="flex items-center justify-between py-3">
        <span
          className="text-[#484848] text-sm font-semibold"
          data-testid="chart-title"
        >
          {config.TITLE}
        </span>
      </div>

      <div
        ref={ref}
        className="h-[400px]"
      />
    </div>
  );
};
