import { http, HttpResponse } from 'msw';

export const USER_SEMANTIC_DESCRIPTION_INPUT =
  'Companies with expertise in pharmaceuticals or medical devices especially those with patented products';

export const getImprovedSemanticDescriptionResponse = (description: string) => `Improved description ${description}`;

export const semanticController = [
  http.post(`${process.env.REACT_APP_API_URL}/search/semantic`, async ({ request }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requestBody = (await request.json()) as any;
    const requestedDescription = requestBody?.description;

    return HttpResponse.json({
      filters: {
        self_sector_sector___: [],
      },
      keywords: ['AI'],
      description: requestedDescription,
      improved_description: getImprovedSemanticDescriptionResponse(requestedDescription),
      bad_references: [''],
    });
  }),
];
