import { useQuery } from '@tanstack/react-query';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { fetchChartFilters } from '@/services/api/charts';

export const useQueryChartFilters = (chartId: number, bain_id?: string) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const currency = useShallowSelector((state) => state.config.currency);

  return useQuery(['chart-filters', chartId, bain_id, currency], () => fetchChartFilters(chartId, bain_id as string), {
    enabled: isAuth && !!bain_id,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: 1,
  });
};
