import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import Button, { ButtonProps } from '@mui/material/Button';

interface CustomButtonProps extends PropsWithChildren<ButtonProps> {
  className?: string;
}

export const CustomButton = ({ children, className, ...props }: CustomButtonProps) => {
  return (
    <Button
      className={classnames(
        'px-4 py-2 border-bluegray-900 text-bluegray-900  border border-solid hover:bg-bluegray-900 hover:text-white font-graphik text-sm font-medium h-[36px]',
        className,
      )}
      data-testid="advanced-button"
      {...props}
    >
      {children}
    </Button>
  );
};
