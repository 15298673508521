import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import { DatumDatum } from '@/types';

interface IProps {
  row?: DatumDatum;
}

export const ProfileHeaderIndustryItem = ({ row }: IProps) => {
  const sector = row?.self_sector_sector___;
  const industryGroup = row?.self_sector_industryGroup___;
  const industry = row?.self_sector_industry___;
  const subIndustry = row?.self_sector_subIndustry___;

  return sector ? (
    <Tooltip
      title={
        <div className="flex flex-col gap-1 p-2 text-xs">
          <span>Industry</span>

          <span className="font-normal">{`${sector} > ${industryGroup} > ${industry} > ${subIndustry}`}</span>
        </div>
      }
      classes={{ tooltip: 'max-w-fit bg-[#484848]', arrow: 'text-[#484848]' }}
      placement="top-start"
      arrow
    >
      <div
        className="flex gap-1 border border-[transparent] border-solid hover:border-[#78c3fd] rounded"
        data-testid="profile-header-item-industry"
      >
        <span className="px-2 py-1 bg-[#ecf6fd] text-[#484848] text-sm font-medium rounded">{sector}</span>
      </div>
    </Tooltip>
  ) : null;
};
