import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import { Loader } from '../../Loader/Loader';
import { AddToListDropdown } from './AddToListDropdown';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { useMutationUpdateCompaniesList } from '@/hooks/queries/companies-list/use-mutation-update-companies-list';
import { useMutationTriggerSimilarCompaniesSearch } from '@/hooks/queries/similar-companies/use-mutation-trigger-similar-companies-search';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { actions } from '@/slices/selected-companies';
import { useModal } from '@/hooks/use-modal';
import { useMenu } from '@/hooks/use-menu';
import { useAllFetchedTableRows } from '@/hooks/table/use-all-fetched-table-rows';
import { MODALS, ROUTES } from '@/constants';
import { getUuidFromStringEnd } from '@/Utils/url-utils';
import { useQueryCompaniesListById } from '@/hooks/queries/companies-list/use-query-companies-list-by-id';

export const FIND_SIMILAR_BUTTON_TEXT = 'Find similar';
export const HIDE_FIND_SIMILAR_BUTTON = true;

export const SelectCompaniesControls = ({ readOnlyMode = false }) => {
  const [isRemovingSelectedCompanies, setIsRemovingSelectedCompanies] = useState(false);
  const params = useParams();
  const location = useLocation();
  const { getRowByBainId } = useAllFetchedTableRows();
  const urlCompaniesListId = params?.id && getUuidFromStringEnd(params.id);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQueryCompaniesLists();

  const listQueryDelete = useQueryCompaniesListById(urlCompaniesListId);
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const { handleOpen: handleOpenNewCompaniesListDialog } = useModal(MODALS.CREATE_COMPANIES_LIST);
  const updateCompaniesListMutation = useMutationUpdateCompaniesList(handleClose);
  const triggerSimilarCompaniesSearchMutation = useMutationTriggerSimilarCompaniesSearch();
  const selectedCompanies = useShallowSelector((state) => state.selectedCompanies.selectedCompanies);
  const areCompaniesSelected = selectedCompanies.length > 0;
  const isOneCompanySelected = selectedCompanies.length === 1;
  const companiesLists = query.data ?? [];

  const handleResetCompanies = () => {
    dispatch(actions.reset());
  };

  const handleOpenDialog = (event) => {
    companiesLists.length > 0 ? handleClick(event) : handleOpenNewCompaniesListDialog();
  };

  const removeSelectedCompanies = () => {
    if (urlCompaniesListId) {
      setIsRemovingSelectedCompanies(true);
      const currentListIds = listQueryDelete.data?.pinned_company_ids ?? [];
      const pinned_company_ids = _.without(currentListIds, ...selectedCompanies);

      updateCompaniesListMutation.mutate(
        {
          id: urlCompaniesListId,
          fieldsToUpdate: { pinned_company_ids, pinned_company_ids_count: pinned_company_ids.length },
          invalidateResponse: true,
        },
        {
          onSuccess: (response) => {
            enqueueSnackbar(
              <NotificationMessage title={`The ${response.title} list has been successfully updated`} />,
              { variant: 'success' },
            );
          },
          onSettled: () => setIsRemovingSelectedCompanies(false),
        },
      );
    }
  };

  const findSimilarCompanies = () => {
    if (!isOneCompanySelected) return;

    const companyRow = getRowByBainId(selectedCompanies[0]);

    if (!companyRow) {
      enqueueSnackbar(
        <NotificationMessage title="Failed to trigger similar companies search. Please try again or contact support." />,
        { variant: 'error' },
      );
    }

    triggerSimilarCompaniesSearchMutation.mutate({
      companies: [
        {
          name: companyRow?.['self_firmo_name___'],
          description: companyRow?.['self_firmo_description___'],
        },
      ],
    });
  };

  const isCustomScreen = location.pathname === ROUTES.CUSTOM_SEARCH;

  return areCompaniesSelected ? (
    <div data-testid="search-list-controls">
      <span className="flex items-center font-medium m-auto text-white text-sm h-full">
        <span className="bg-bluegray-900 inline-flex h-full items-center rounded rounded-r-none pl-3">
          {selectedCompanies.length.toLocaleString('en-US')} companies selected
          <IconButton
            onClick={handleResetCompanies}
            className="text-white p-1 ml-0"
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </span>
        {isOneCompanySelected && isCustomScreen && !HIDE_FIND_SIMILAR_BUTTON ? (
          <Button
            className="bg-bluegray-900 rounded-none text-white px-3 text-sm font-medium ml-[1px] border-white h-full w-[100px]"
            onClick={findSimilarCompanies}
          >
            {triggerSimilarCompaniesSearchMutation.isLoading ? (
              <div className="w-full flex justify-center">
                <Loader />
              </div>
            ) : (
              FIND_SIMILAR_BUTTON_TEXT
            )}
          </Button>
        ) : null}
        {readOnlyMode ? null : (
          <Button
            className="bg-bluegray-900 rounded-none text-white px-3 text-sm font-medium ml-[1px] border-white h-full"
            onClick={handleOpenDialog}
          >
            Add to list
          </Button>
        )}
        {urlCompaniesListId && companiesLists.find((list) => list.list_id === urlCompaniesListId) && !readOnlyMode && (
          <Button
            className="bg-bluegray-900 rounded-none text-white px-3 text-sm font-medium ml-[1px] border-white h-full w-[70px]"
            onClick={removeSelectedCompanies}
          >
            {isRemovingSelectedCompanies ? (
              <div className="w-full flex justify-center">
                <Loader />
              </div>
            ) : (
              'Remove'
            )}
          </Button>
        )}
      </span>

      <AddToListDropdown
        isOpen={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
        selectedCompanies={selectedCompanies}
        companiesLists={companiesLists}
      />
    </div>
  ) : null;
};
