import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Alert from '@mui/material/Alert';
import classnames from 'classnames';

import { Tab as TabType } from '../../types';
import { GenericTableTab } from '../GenericTableTab/GenericTableTab';
import { PivotTableTab } from '../PivotTableTab/PivotTableTab';
import { GenericTilesTable } from '../GenericTilesTable/GenericTilesTable';
import { Highlights } from './highlights';
import { PeopleCharts } from './charts';
import { Sentiment } from './sentiment';
import { isTabEnabled } from './utils';
import { ChartConfigType, DatumDatum } from '@/types';

interface IProps {
  bainId: string;
  tabSlug: string;
  tabs: TabType[];
  params?: { row: DatumDatum };
  chartConfigs: ChartConfigType[];
}

export const PeopleTab = ({ bainId, chartConfigs, tabs, params, tabSlug }: IProps) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (_: React.SyntheticEvent, value: number) => {
    setCurrentTab(value);
  };

  const activeTabConfig = tabs[currentTab];

  return (
    <div>
      <Tabs
        value={currentTab}
        id="people-tab-container"
        onChange={handleChange}
        variant="fullWidth"
        className="border border-[#2e3f4c] rounded min-h-[30px]"
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
      >
        {tabs.map((tab, idx) => {
          const isDisabled = !isTabEnabled(tab, params);

          return (
            <Tab
              key={tab.id}
              label={tab.label}
              id={tab.id}
              disabled={isDisabled}
              className={classnames('text-sm p-0 min-h-[30px] font-medium border-solid	border-[#2e3f4c]', {
                'opacity-50': isDisabled,
                'bg-[#2e3f4c] text-white font-semibold': currentTab === idx,
                'text-[rgb(46,63,76)]': currentTab !== idx,
                'border-r': tabs.length !== idx + 1,
              })}
            />
          );
        })}
      </Tabs>

      {activeTabConfig?.components?.map(({ component, properties }, index) => {
        if (component === 'HighlightsComponent' && properties?.metrics?.length && params) {
          return (
            <Highlights
              key={component + index}
              params={params}
              metrics={properties.metrics}
            />
          );
        }

        if (component === 'InfoComponent' && properties?.content) {
          return (
            <div
              className="mt-4"
              key={component + index}
            >
              <Alert
                severity="info"
                className="bg-[#ecf6fd] "
              >
                {properties?.content}
              </Alert>
            </div>
          );
        }

        if (component === 'MultipleChart' && properties?.section) {
          return (
            <PeopleCharts
              tabSlug={tabSlug}
              key={component + index}
              bainId={bainId}
              section={properties.section}
              chartConfigs={chartConfigs.filter((item) => item.SECTION === properties.section)}
            />
          );
        }

        if (component === 'GenericTableTab' && properties && 'dataset' in properties) {
          return (
            <GenericTableTab
              key={component + index}
              tabSlug={tabSlug}
              bainId={bainId}
              dataset={''}
              {...properties}
            />
          );
        }

        if (
          component === 'PivotTableTab' &&
          properties &&
          'dataset' in properties &&
          'pivotColumn' in properties &&
          'valueColumn' in properties
        ) {
          return (
            <PivotTableTab
              key={component + index}
              bainId={bainId}
              dataset={''}
              pivotColumn={''}
              valueColumn={''}
              {...properties}
            />
          );
        }

        if (
          component === 'GenericTilesTable' &&
          properties &&
          'dataset' in properties &&
          'title' in properties &&
          'sortField' in properties
        ) {
          return (
            <GenericTilesTable
              key={component + index}
              bainId={bainId}
              title={properties.title}
              dataset={properties.dataset}
              sortField={properties.sortField}
              {...properties}
            />
          );
        }

        if (component === 'Sentiment') {
          return (
            <Sentiment
              key={component + index}
              bainId={bainId}
            />
          );
        }
      })}
    </div>
  );
};
