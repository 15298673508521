import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import Drawer from '@mui/material/Drawer';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { reduce, isEmpty } from 'lodash';

import { TextField } from '@mui/material';
import { useDebounce } from 'usehooks-ts';
import { useFiltersHasChange } from './use-filters-has-change';
import { FiltersTabs } from './FiltersTabs';
import { IconFilters } from '@/Components/Icons/IconFilters';
import { useQueryAvailableFilters } from '@/hooks/queries/available-filters/use-query-available-filters';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useSearchCompanies } from '@/hooks/use-search-companies';
import { SEARCH_EVENTS } from '@/constants';
import { QueryType, actions as searchActions } from '@/slices/search';
import { actions as tableActions } from '@/slices/table';
import { useQueryFilterTree } from '@/hooks/queries/use-query-filter-tree';
import { pluck } from '@/Utils';
import { getFilteredFilters, getFilters } from '@/Utils/filters';
import { FiltersAvailableData } from '@/types';
import {
  checkIfAggregationIsCustom,
  getAggregationString,
  useFiltersToDisplay,
} from '@/Components/CompanyResearch/SavedFilters/use-filters-to-display';
import { useSimplifyCustomAggregationString } from '@/hooks/queries/custom-aggregation/use-simplify-custom-aggregation-string';
import { IconSearch } from '@/Components/Icons/IconSearch';
import { CustomButton } from '@/Components/CompanyResearch/Search/Buttons/CustomButton';

const APPROX_ANIMATION_DURATION = 1000;

interface FiltersDrawerProps {
  isSemanticSearch?: boolean;
  isSimilarCompanies?: boolean;
  showFilterIds?: string[];
}

const getAvailableFilters = (preFilterIds: string[], filters: FiltersAvailableData[]) =>
  preFilterIds.length > 0 ? filters.filter((item) => preFilterIds.includes(item.displayHeader)) : filters;

export const FiltersDrawer = ({
  isSimilarCompanies = false,
  isSemanticSearch,
  showFilterIds = [],
}: FiltersDrawerProps) => {
  const dispatch = useDispatch();
  const availableFiltersQuery = useQueryAvailableFilters();
  const { queryType, searchText, isFiltersDrawerOpen } = useShallowSelector((state) => state.search);
  const searchCompanies = useSearchCompanies();
  const filters = useMemo(() => availableFiltersQuery.data ?? [], [availableFiltersQuery.data]);
  const availableFilters = getAvailableFilters(showFilterIds, filters);
  const allAppliedFilters = useShallowSelector((state) => state.filters);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [blockStatsCall, setBlockStatsCall] = useState(false);

  const { hasChange, setInitialFiltersHash } = useFiltersHasChange({
    initialCondition: isFiltersDrawerOpen,
  });

  const { simplifyCustomAggregationString, isSimplifying } = useSimplifyCustomAggregationString();

  const { getFiltersAggregation } = useFiltersToDisplay();
  const filtersAggregation = getFiltersAggregation();

  useEffect(() => {
    const filterColumns = Object.keys(getFilters(allAppliedFilters));

    dispatch(tableActions.setFilterColumns(filterColumns));
  }, [allAppliedFilters, dispatch]);

  const filterItems = availableFilters.reduce(
    (previous: string[], current) => [...previous, ...pluck('backendName', current.items)],
    [],
  );

  const {
    data: filterStatsData,
    isFetching: isFilterStatsFetching,
    error: filterStatsError,
  } = useQueryFilterTree({
    ids: filterItems,
    enabled: !!availableFilters.length,
    body: { company_search: '', is_lucene: true },
  });

  const isFilterApplied = useMemo(
    () =>
      reduce(
        { ...allAppliedFilters.treeFilters, ...allAppliedFilters.otherFilters },
        (__, item) => !isEmpty(item),
        false,
      ),
    [allAppliedFilters],
  );

  const handleOpen = () => {
    dispatch(searchActions.setIsFiltersDrawerOpen(true));
  };

  const proceedSearch = () => {
    if (!isSimilarCompanies) {
      const isSmartSearch = queryType === QueryType.SMART;

      searchCompanies({
        searchText: isSmartSearch ? '' : searchText,
        eventName: SEARCH_EVENTS.CHANGE_FILTERS,
        queryType,
      });
    }

    setSearchValue('');
    setInitialFiltersHash('');
    dispatch(searchActions.setIsFiltersDrawerOpen(false));
  };

  const handleClose = () => {
    if (!filtersAggregation || !hasChange) {
      proceedSearch();

      return;
    }

    const aggregationString = getAggregationString(filtersAggregation);
    const isCustomAggregation = checkIfAggregationIsCustom(filtersAggregation);

    if (!aggregationString || !isCustomAggregation) {
      proceedSearch();

      return;
    }

    simplifyCustomAggregationString({
      customAggregationStringToSimplify: aggregationString,
      onSuccess: proceedSearch,
    });
  };

  const filtersToRender: FiltersAvailableData[] = useMemo(() => {
    const availableFiltersWithDetails = availableFilters.map((filter) => ({
      ...filter,
      items: filter.items.map((item) => ({ ...item, filterItems: filterStatsData?.[item.backendName] ?? [] })),
    }));

    if (debouncedSearchValue.length < 3) return availableFiltersWithDetails;

    return getFilteredFilters(availableFiltersWithDetails, debouncedSearchValue) ?? [];
  }, [availableFilters, filterStatsData, debouncedSearchValue]);

  const clearInput = () => {
    setSearchValue('');
    setBlockStatsCall(true);

    setTimeout(() => {
      setBlockStatsCall(false);
    }, APPROX_ANIMATION_DURATION);
  };

  const error = filterStatsError || availableFiltersQuery.error ? <div>Something went wrong</div> : null;
  const loading =
    (availableFiltersQuery.isLoading || isFilterStatsFetching || !filterStatsData) && !error ? (
      <Box className="flex justify-center">
        <CircularProgress />
      </Box>
    ) : null;

  return (
    <>
      <CustomButton
        startIcon={<IconFilters fill="currentColor" />}
        endIcon={
          isFilterApplied ? (
            <CheckIcon
              className="ml-1"
              data-testid="filters-applied-icon"
            />
          ) : null
        }
        className={classnames({
          'bg-[#fff] text-bluegray-900': !isFilterApplied,
          'bg-bluegray-900 text-white': isFilterApplied,
        })}
        onClick={handleOpen}
        data-testid="filters-button"
      >
        Filters
      </CustomButton>

      <Drawer
        anchor="right"
        open={isFiltersDrawerOpen}
        onClose={handleClose}
        classes={{
          paper: 'w-[35vw] shadow-[-2px_0px_4px_rgba(0,0,0,0.25)]',
        }}
      >
        <div className="flex items-center px-6 pt-4 justify-between mb-4">
          <span className="font-medium text-lg text-[#484848S] flex items-center">
            Filters
            {isSimplifying ? (
              <CircularProgress
                className="text-[#ddd] ml-3"
                size={20}
              />
            ) : null}
          </span>
          <IconButton
            data-testid="close-filters-drawer"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {error}
        {loading}
        {filterStatsData && availableFiltersQuery.data ? (
          <>
            <div className="sticky top-3 z-10 bg-[#fff] mb-7 mt-0 px-6">
              <TextField
                size="small"
                fullWidth
                placeholder="Search filters... (min. 3 characters)"
                onChange={(event) => setSearchValue(event.target.value)}
                value={searchValue}
                inputProps={{
                  'data-testid': 'filter-search-input',
                }}
                InputProps={{
                  startAdornment: <IconSearch className="mr-2 ml-[-6px]" />,
                  endAdornment: searchValue?.length ? (
                    <IconButton
                      onClick={clearInput}
                      size="small"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  ) : undefined,
                }}
              />
            </div>

            <FiltersTabs
              allFiltersToRender={filtersToRender}
              searchValue={debouncedSearchValue.length < 3 ? '' : debouncedSearchValue}
              blockStatsCall={blockStatsCall}
              isSemanticSearch={isSemanticSearch}
              isSimilarCompanies={isSimilarCompanies}
            />
          </>
        ) : null}
      </Drawer>
    </>
  );
};
