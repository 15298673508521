import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import classNames from 'classnames';

interface IProps {
  order: 'asc' | 'desc';
  active?: boolean;
}

export const HeaderSortButton = ({ order, active }: IProps) => {
  const iconClassName = classNames('w-[18px] h-[18px]', {
    'opacity-50': !active,
    'opacity-95': active,
  });

  return (
    <Tooltip title="Sort">
      <IconButton className="w-[28px] h-[28px] ml-1 mr-1 mb-1">
        {order === 'asc' ? (
          <ArrowUpwardIcon className={iconClassName} />
        ) : (
          <ArrowDownwardIcon className={iconClassName} />
        )}
      </IconButton>
    </Tooltip>
  );
};
