import { http, HttpResponse } from 'msw';
import { MOCK_COMPANY_DATA } from './elastic-controller';
import { IGroupedAutocompleteResponseCompanySearchItem } from '@/types';

export const createGroupingCompany = (
  name: string,
  bainId: number,
  hasRelated: boolean,
): IGroupedAutocompleteResponseCompanySearchItem => ({
  self_firmo_description___: `${name} description`,
  self_sector_industry___: 'Consumer Electronics',
  self_location_country___: 'United States',
  self_firmo_name___: `${name} name`,
  has_related: hasRelated,
  self_logourl: `${name} logo`,
  bain_id: bainId,
  self_financials_revenue___: 383285000000,
});

export const HAS_RELATED_SEARCH_RESULT = createGroupingCompany('Test company has related', 555, true);

export const HAS_NOT_RELATED_SEARCH_RESULT = createGroupingCompany('Test company has not related', 777, false);

const HAS_NOT_RELATED_SEARCH_RESULT_GROUPING_1 = createGroupingCompany(
  'Test company han not related grouping 1',
  888,
  false,
);
const HAS_NOT_RELATED_SEARCH_RESULT_GROUPING_2 = createGroupingCompany(
  'Test company han not related grouping 2',
  999,
  false,
);

export const autocompleteController = [
  http.post(`${process.env.REACT_APP_API_URL}/search/autocomplete/clustered`, async ({ request }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requestBody = (await request.json()) as any;
    const requestBainId = requestBody?.bain_id;

    return HttpResponse.json({
      data: requestBainId
        ? [HAS_NOT_RELATED_SEARCH_RESULT_GROUPING_1, HAS_NOT_RELATED_SEARCH_RESULT_GROUPING_2]
        : [HAS_RELATED_SEARCH_RESULT],
    });
  }),
  http.post(`${process.env.REACT_APP_API_URL}/rank_search`, async () => {
    return HttpResponse.json(MOCK_COMPANY_DATA);
  }),
];
