import React from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import classNames from 'classnames';
import { StarterPackWizardStepItemChoice } from '@/types';

export interface WizardDialogChoiceProps {
  item: StarterPackWizardStepItemChoice;
  value: string;
  onChange: (value: string) => void;
}

export const WizardDialogChoice = ({ item, value, onChange }: WizardDialogChoiceProps) => (
  <div className="my-5">
    <p className="font-medium text-sm">{item.question}</p>
    <FormControl className="w-full">
      <RadioGroup
        value={value}
        onChange={(event: SelectChangeEvent) => onChange(event?.target.value)}
      >
        {item.options.map((option) => (
          <FormControlLabel
            key={option.option}
            value={option.option}
            control={
              <Radio
                color="info"
                sx={{
                  color: '#ddd',
                }}
              />
            }
            label={
              <div className="flex flex-col text-[#484848]">
                <span className="font-medium">{option.option}</span>
                {option.description ? <span className="w-[80%] mt-1">{option.description}</span> : null}
              </div>
            }
            labelPlacement="start"
            className={classNames('border rounded m-0 mt-4 p-2 pl-4 flex justify-between ', {
              'items-start': !!option.description,
            })}
          />
        ))}
      </RadioGroup>
    </FormControl>
  </div>
);
