import { useQueries } from '@tanstack/react-query';
import { isNil } from 'lodash';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { fetchChartData } from '@/services/api/charts';
import { CHART_SECTIONS } from '@/constants';
import { ChartDataType } from '@/types';

export const useQueriesChartComprasion = (
  bainId: string,
  section: string,
  currentChartId: number,
) => {
  const compareChartList = useShallowSelector(state => state.charts.compare);
  const currency = useShallowSelector((state) => state.config.currency);
  const financialDataSource = useShallowSelector((state) => state.companyProfile.financialDataSource);
  const dataSource = section === CHART_SECTIONS.FINANCIALS ? financialDataSource : null;
  const isEnabled = section === CHART_SECTIONS.FINANCIALS ? !isNil(dataSource) : true;
  const filteredChartList = compareChartList.filter(item => item.chartId === currentChartId && item.bainId !== bainId);

  return useQueries({
    queries: filteredChartList.map(item => ({
      enabled: isEnabled,
      queryKey: ['chart-comparison', item.chartId, item.bainId, currency, dataSource],
      queryFn: () => fetchChartData({ chartId: item.chartId, bainId: item.bainId, currency, dataSource }),
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 1,
      select: (data: ChartDataType[]) => {
        return {
          bainId: item.bainId,
          data,
        };
      },
    })),
  });
};
