import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FinancialDataSourceType } from '@/types';

const initialState = {
  financialDataSource: null as FinancialDataSourceType,
  genericTabDataSource: {} as Record<string, string | null>,
};

export const { reducer, actions } = createSlice({
  name: 'companyProfile',
  initialState,
  reducers: {
    setFinancialDataSource: (state, { payload }: PayloadAction<FinancialDataSourceType>) => {
      state.financialDataSource = payload;
    },
    setGenericTabDataSource: (
      state,
      { payload: { tabSlug, dataSource } }: PayloadAction<{ tabSlug: string; dataSource: string | null }>,
    ) => {
      state.genericTabDataSource[tabSlug] = dataSource;
    },
  },
});

export type TState = typeof initialState;
