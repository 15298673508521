import React from 'react';

import { GroupedMatchSearchItem } from '../GroupedMatchSearchItem';
import { IGroupedAutocompleteResponseCompanySearchItem } from '@/types';
import { Loader } from '@/Components/Shared/Loader/Loader';

const IS_GROUPING_ENABLED = false;

export interface GroupedMatchSearchResultsProps {
  data?: IGroupedAutocompleteResponseCompanySearchItem[];
  searchText: string;
  isMatchSearchLoading: boolean;
  isRankSearchLoading: boolean;
  isFetched: boolean;
  isRankSearch: boolean;
  error: unknown;
}

export const GroupedMatchSearchResults = ({
  data,
  searchText,
  error,
  isMatchSearchLoading,
  isRankSearchLoading,
  isFetched,
  isRankSearch,
}: GroupedMatchSearchResultsProps) => {
  const isLoading = isRankSearchLoading || isMatchSearchLoading;

  if (!isFetched) return null;

  if (data?.length === 0 && !isLoading) {
    return <div className="mt-6 text-sm font-normal text-[#484848]">Nothing found</div>;
  }

  if (error) {
    return <div className="mt-6 text-sm font-normal text-[#484848]">Something went wrong</div>;
  }

  const [topResult, ...restResults] = data ?? [];

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          {isLoading && <Loader />}
          <p className=" text-xs font-medium text-[#666] my-6">TOP RESULT</p>
        </div>

        {isRankSearch && (
          <div className="flex items-center gap-1">
            <p className="italic text-[10px] text-[#666666]">Powered by Perplexity AI</p>
            <img
              src="/images/perplexity.png"
              alt="perplexity"
              width={16}
              height={16}
            />
          </div>
        )}
      </div>

      {topResult && !isRankSearchLoading && (
        <GroupedMatchSearchItem
          searchText={searchText}
          searchResult={topResult}
          isGroupingEnabled={IS_GROUPING_ENABLED}
        />
      )}

      <div>
        <div className="flex items-center gap-3">
          {isMatchSearchLoading && <Loader />}
          <p className=" text-xs font-medium text-[#666] my-6">MATCHED NAME, URL OR DESCRIPTION</p>
        </div>

        <div className="flex flex-col gap-6">
          {restResults.map((result) => (
            <GroupedMatchSearchItem
              key={result.bain_id}
              searchText={searchText}
              searchResult={result}
              isGroupingEnabled={IS_GROUPING_ENABLED}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
