import React, { useState } from 'react';
import classnames from 'classnames';
import { Skeleton } from '@mui/material';
import { range } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { GroupedMatchSearchItemCard } from '../GroupedMatchSearchItemCard';
import { IGroupedAutocompleteResponseCompanySearchItem } from '@/types';
import { useQueryGroupedMatchCompanySearch } from '@/hooks/queries';

export interface GroupedMatchSearchItemProps {
  searchText: string;
  searchResult: IGroupedAutocompleteResponseCompanySearchItem;
  isGroupingEnabled: boolean;
}

export const GroupedMatchSearchItem = ({
  searchResult,
  searchText,
  isGroupingEnabled,
}: GroupedMatchSearchItemProps) => {
  const { has_related, bain_id } = searchResult;
  const [isOpen, setIsOpen] = useState(false);
  const {
    data,
    isFetching: isLoading,
    isError,
  } = useQueryGroupedMatchCompanySearch(searchText, isOpen, String(bain_id));

  const onClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const renderCard = (item: IGroupedAutocompleteResponseCompanySearchItem) => (
    <GroupedMatchSearchItemCard
      bainId={String(item.bain_id)}
      name={item.self_firmo_name___}
      country={item.self_location_country___}
      industry={item.self_sector_industry___}
      revenue={item.self_financials_revenue___}
      logoUrl={item.self_logourl}
    />
  );

  return (
    <div className="rounded border border-[#ddd] shadow-[0_1px_3px_0px_rgba(46,48,48,0.14)]">
      {renderCard(searchResult)}

      {isGroupingEnabled && has_related && !isError ? (
        <>
          <div className="h-[1px] bg-[#DDD]" />

          {isLoading
            ? range(2).map((idx) => (
                <Skeleton
                  key={idx}
                  variant="rounded"
                  className="bg-[#0000000f] rounded-2xl my-4 mx-4"
                  height={60}
                />
              ))
            : null}

          {isOpen && data
            ? data.map((relatedItem) => (
                <div
                  key={relatedItem.bain_id}
                  className="ml-5"
                >
                  {renderCard(relatedItem)}
                </div>
              ))
            : null}

          <div className="p-2 pb-4 flex items-center justify-center">
            <button
              className="text-[#2E3F4C] font-medium"
              onClick={onClick}
            >
              <span>
                {isOpen ? 'Show less' : 'Show more'}
                <KeyboardArrowDownIcon
                  className={classnames('fill-[#484848] text-xl transition ml-1', {
                    'rotate-180': isOpen,
                  })}
                />
              </span>
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};
