import React from 'react';
import cn from 'classnames';

import { useQueriesChartData } from '@/hooks/queries';
import { ChartConfigType, ChartDatasetType } from '@/types';
import { ChartCommon } from '@/Components/Shared/Charts';

interface IProps {
  bainId: string;
  section: string;
  chartConfigs: ChartConfigType[];
  tabSlug: string;
}

const LAYOUTS = {
  'Workforce:Geographic Footprint': 'grid-cols-1',
} as Record<string, string>;

const ROLES_OVER_TIME_CHART_ID = 15;

const getLayout = (section: string) => {
  return LAYOUTS[section] ?? 'grid-cols-2';
};

export const PeopleCharts = ({ bainId, section, chartConfigs, tabSlug }: IProps) => {
  const queries = useQueriesChartData(chartConfigs, bainId, section);
  const isFetched = queries.every((item) => !item.isFetching);
  const isEmpty = isFetched && queries.every((item) => item.data?.data?.length === 0);
  const layout = getLayout(chartConfigs[0].SECTION as string);

  return (
    <div className={cn('grid gap-4 mt-4 mb-4', layout)}>
      {chartConfigs
        .filter((_, idx) => idx < 4)
        .map((config, idx) => {
          const chartData = queries[idx].data;

          if (chartData?.data.length === 0) return;

          return (
            <div
              key={config.ID}
              style={{ order: config.ID === ROLES_OVER_TIME_CHART_ID ? 1 : idx + 2 }}
              className={cn('h-full', {
                'col-span-2': config.ID === ROLES_OVER_TIME_CHART_ID,
              })}
            >
              <ChartCommon
                tabSlug={tabSlug}
                data={chartData as ChartDatasetType}
                section={section}
                isLoading={queries[idx].isLoading}
                config={config}
              />
            </div>
          );
        })}

      {isEmpty && <div>Charts are currently unavailable due to insufficient data.</div>}
    </div>
  );
};
