import { Workbook } from 'exceljs';
import { toPng } from 'html-to-image';

type SheetData = {
  name: string;
  data: Record<string, unknown>[];
};

type ExcelConfig = {
  fileName: string;
  sheets: SheetData[];
};

export const downloadChartExcel = async (config: ExcelConfig) => {
  const workbook = new Workbook();

  config.sheets.forEach((sheet) => {
    if (sheet.data.length === 0) return;

    const worksheet = workbook.addWorksheet(sheet.name);
    const headers = Object.keys(sheet.data[0]);

    worksheet.addRow(headers);

    sheet.data.forEach((row) => {
      worksheet.addRow(Object.values(row));
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = url;
  a.download = config.fileName;
  a.click();

  window.URL.revokeObjectURL(url);
};

export const downloadChartImg = async (containerRef: React.RefObject<HTMLDivElement | null>, filename: string) => {
  if (!containerRef.current) return;

  try {
    const dataUrl = await toPng(containerRef.current, {
      quality: 1.0,
      backgroundColor: '#fff',
      pixelRatio: 2,
    });

    const link = document.createElement('a');

    link.download = filename;
    link.href = dataUrl;
    link.click();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error:', error);
  }
};
