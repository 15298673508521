import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';

import classNames from 'classnames';
import { HeaderSearchButton } from '../HeaderSearchButton';
import { HeaderSortButton } from '../HeaderSortButton';
import { useTableLoading } from '@/hooks/table/use-table-loading';
import { useShallowSelector } from '@/hooks/use-shallow-selector';

interface IProps {
  field: string;
  headerName: string;
  columnMetadata?: string;
  hasConfidenceScore?: boolean;
  sortable?: boolean;
}

export const CompanyHeader = ({ field, headerName, columnMetadata, hasConfidenceScore, sortable }: IProps) => {
  const isLoading = useTableLoading();
  const sortModel = useShallowSelector((state) => state.table.sortModel);

  const currentSorting = sortModel.find((model) => model.field === field);

  return isLoading ? (
    <Skeleton className="w-full h-full bg-[#0000000f]" />
  ) : (
    <div
      className={classNames('flex items-center justify-between w-full group', {
        'ml-4': hasConfidenceScore,
      })}
    >
      <Tooltip
        title={columnMetadata}
        arrow
      >
        <span
          className="MuiDataGrid-columnHeaderTitle text-xs font-medium"
          style={{ marginLeft: '0', marginTop: '-2px' }}
        >
          {headerName}
        </span>
      </Tooltip>

      <div className="flex items-center gap-1">
        {sortable ? (
          <div className={!currentSorting ? 'hidden group-hover:block' : undefined}>
            <HeaderSortButton
              order={currentSorting?.sort ?? 'asc'}
              active={!!currentSorting}
            />
          </div>
        ) : null}

        <HeaderSearchButton field={field} />
      </div>
    </div>
  );
};
