import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { fetchFilteredChartData } from '@/services/api/charts';
import { ChartDatasetType, ChartDataType } from '@/types';

export const useQueryChartFilteredData = (chartId: number, bainId: string) => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const currency = useShallowSelector((state) => state.config.currency);
  const chartFilters = useShallowSelector(state => state.charts.filters[chartId]);
  const body = chartFilters ? {
    filters: {
      [chartFilters.name]: chartFilters.value,
    },
  } : {};
  const isEmpty = _.isEmpty(body);

  return useQuery(['chart-filtered-data', chartId, bainId, currency, body], () => fetchFilteredChartData({ chartId, bainId, currency, body }), {
    enabled: isAuth && !isEmpty,
    cacheTime: Infinity,
    staleTime: Infinity,
    select: (data: ChartDataType[]) => {
      return {
        bainId,
        data,
      } as ChartDatasetType;
    },
    retry: 1,
  });
};
