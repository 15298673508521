import React from 'react';
import { useParams } from 'react-router';
import { isNil } from 'lodash';
import { ExportDropdown } from './CompanyResearch/ExportDropdown';
import { SelectCompaniesControls } from '@/Components/Shared/CompaniesList/SelectCompaniesControls';

interface IProps {
  companyProfileId?: string;
  isCompaniesList?: boolean;
}

const ExportComponent = ({ companyProfileId, isCompaniesList = false }: IProps) => {
  const { revision: revisionUrlParam } = useParams<{ revision?: string }>();

  const revision = isNaN(Number(revisionUrlParam)) ? undefined : Number(revisionUrlParam);

  return (
    <div
      className="flex justify-end h-[36px]"
      data-testid="exportComponent"
    >
      {!companyProfileId ? <SelectCompaniesControls readOnlyMode={isCompaniesList && !isNil(revision)} /> : null}

      <ExportDropdown companyProfileId={companyProfileId} />
    </div>
  );
};

export default ExportComponent;
