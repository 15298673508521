import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { ChartLegendItem } from '@/types';
import { CompareChartsType } from '@/types/state/charts';
import { useQueriesChartCompany } from '@/hooks/queries';

export interface CustomLegendProps {
  bainId: string;
  chartId: number;
  groupedLegends: Map<string, ChartLegendItem[]>;
  onRemoveDataset: (data: CompareChartsType) => void;
}

const LegendContent = ({ legend }: { legend: ChartLegendItem }) => {
  if (legend.isEmpty) {
    return (
      <div className="inline-flex items-center p-1 border border-[#979797]">
        <span className="text-xs">No data for {legend.text}</span>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2 text-xs">
      <span
        className="w-4 h-4 rounded flex-shrink-0"
        style={{
          backgroundColor: legend.color,
          borderColor: legend.color,
        }}
      />
      <span className="legend-text">{legend.text}</span>
    </div>
  );
};

export const CustomLegend = ({
  bainId: currentBainId,
  chartId,
  groupedLegends,
  onRemoveDataset,
}: CustomLegendProps) => {
  const { data: companies } = useQueriesChartCompany(currentBainId);

  return (
    <div className="flex flex-col gap-3">
      {Array.from(groupedLegends).map(([bainId, legends], idx) => (
        <div
          key={bainId}
          className="flex gap-3 items-center"
        >
          <span className="text-xs font-medium">{companies[bainId]}</span>

          <div className="flex items-center gap-3 flex-wrap">
            {legends.map((legend, index) => (
              <div
                key={`${legend.id}_${index}`}
                className="flex items-center"
              >
                <LegendContent legend={legend} />
              </div>
            ))}
          </div>

          {idx > 0 && (
            <div className="flex items-center">
              <IconButton
                size="small"
                className="flex items-center justify-center"
                onClick={() => onRemoveDataset({ chartId, bainId })}
                data-testid={`remove-button-${bainId}`}
              >
                <CloseIcon className="w-3	h-3" />
              </IconButton>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
