import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChartFilterDataPayload, ChartFilterState, CompareChartsType } from '@/types/state/charts';

const initialState = {
  currentChartId: 0,
  filters: {} as ChartFilterState,
  compare: [] as CompareChartsType[],
};

export const { reducer, actions } = createSlice({
  name: 'charts',
  initialState,
  reducers: {
    addCompany: (state, { payload }: PayloadAction<CompareChartsType>) => {
      state.compare.push(payload);
    },
    setCurrentChartId: (state, { payload }: PayloadAction<number>) => {
      state.currentChartId = payload;
    },
    setFilters: (state, { payload }: PayloadAction<ChartFilterDataPayload>) => {
      state.filters[payload.id] = payload.data;
    },
    removeCompany: (state, { payload }: PayloadAction<CompareChartsType>) => {
      state.compare = state.compare.filter(
        item => !(item.bainId === payload.bainId && item.chartId === payload.chartId),
      );
    },
    reset: (state) => {
      state.compare = [];
    },
  },
});

export type TState = typeof initialState;
