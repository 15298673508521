import React from 'react';
import { useParams } from 'react-router';
import { CustomButton } from './CustomButton';
import { ROUTES } from '@/constants';

import { getUuidFromStringEnd } from '@/Utils/url-utils';
import { useQuerySavedSearchById } from '@/hooks/queries/saved-search/use-query-saved-search-by-id';
import { useScreener } from '@/hooks/use-screen';

export const ClearSearchButton = ({ onClick }: { onClick?: () => void }) => {
  const { resetAll, softReset } = useScreener();
  const isSavedSearch = location.pathname.startsWith(ROUTES.SAVED_SEARCH);
  const { id: idWithTitle } = useParams<{ id: string }>();
  const savedSearchId = isSavedSearch ? getUuidFromStringEnd(idWithTitle) : '';
  const savedSearchByIdQuery = useQuerySavedSearchById({ id: savedSearchId });

  const onClear = () => {
    if (savedSearchId) {
      softReset();
      savedSearchByIdQuery.refetch();
    } else {
      resetAll();
    }

    onClick?.();
  };

  return (
    <CustomButton
      onClick={onClear}
      id="custom-reset-button"
    >
      Clear
    </CustomButton>
  );
};
