import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Tooltip } from '@mui/material';
import { useTableLoading } from '@/hooks/table/use-table-loading';

interface IProps {
  headerName: string;
  columnMetadata?: string;
}

export const CommonHeader = ({ headerName, columnMetadata }: IProps) => {
  const isLoading = useTableLoading();

  return isLoading ? (
    <Skeleton className="w-full h-full bg-[#0000000f]" />
  ) : (
    <Tooltip
      title={columnMetadata}
      arrow
    >
      <span className="ml-4 w-full text-xs font-medium">{headerName}</span>
    </Tooltip>
  );
};
