import React, { MutableRefObject, useState } from 'react';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { gridClasses, gridPreferencePanelStateSelector, GridPreferencePanelsValue } from '@mui/x-data-grid-pro';
import { CustomButton } from '@/Components/CompanyResearch/Search/Buttons/CustomButton';

export const COLUMNS_VISIBILITY_PANEL_BUTTON_ID = 'toggle-columns-visibility-panel-button';

export const useShowColumnsVisibilityPanelButton = (apiRef: MutableRefObject<GridApiPro>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const toggleColumnsPanel = () => {
    const preferencePanelState = gridPreferencePanelStateSelector(apiRef.current.state);

    if (preferencePanelState.open) {
      apiRef.current.hidePreferences();
    } else {
      apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
    }
  };

  const showColumnsVisibilityPanelButton = (
    <CustomButton
      onClick={toggleColumnsPanel}
      data-testid={COLUMNS_VISIBILITY_PANEL_BUTTON_ID}
      id={COLUMNS_VISIBILITY_PANEL_BUTTON_ID}
      ref={(ref) => setAnchorEl(ref)}
    >
      Columns
    </CustomButton>
  );

  return {
    columnsVisibilityPanelAnchorEl: () => {
      const preferencePanelState = gridPreferencePanelStateSelector(apiRef.current.state);

      if (preferencePanelState.openedPanelValue === GridPreferencePanelsValue.columns && anchorEl) {
        return anchorEl;
      }

      const columnHeadersElement = apiRef.current.rootElementRef?.current?.querySelector(
        `.${gridClasses.columnHeaders}`,
      );

      return columnHeadersElement;
    },
    showColumnsVisibilityPanelButton,
  };
};
