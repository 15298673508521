import React, { MouseEventHandler, useState } from 'react';
import Popover from '@mui/material/Popover';

import { Badge, IconButton, TextField, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useMenu } from '@/hooks/use-menu';
import { IconSearch } from '@/Components/Icons/IconSearch';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { FilterOperatorEnum } from '@/hooks/table/use-custom-table-transformation';
import { actions as searchActions } from '@/slices/search';

interface IProps {
  field: string;
  disabled?: boolean;
}

export const HeaderSearchButton = ({ field, disabled = false }: IProps) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const dispatch = useDispatch();
  const tableFilters = useShallowSelector((state) => state.search.table_filters);

  const containFilters = tableFilters.filter(
    (filter) => filter.key === field && filter.op === FilterOperatorEnum.CONTAINS,
  );

  const defaultValue = containFilters.length === 1 ? containFilters[0] : undefined;

  const [value, setValue] = useState(defaultValue ? defaultValue.arg : '');

  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleClick(e);
  };

  const search = () => {
    if (!value || value.length === 0) return;

    const tableFiltersWithoutCurrent = tableFilters.filter(
      (filter) => !(filter.key === field && filter.op === FilterOperatorEnum.CONTAINS),
    );

    const tableFiltersToApply = [
      ...tableFiltersWithoutCurrent,
      { key: field, arg: value, op: FilterOperatorEnum.CONTAINS },
    ];

    dispatch(searchActions.setTableFilters(tableFiltersToApply));
    dispatch(searchActions.setIsElasticEnabled(true));
  };

  const handleEnter: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    const isEnter = event?.key === 'Enter';

    if (isEnter) {
      event.preventDefault();
      search();
    }
  };

  const handleClear = () => {
    setValue('');
    const tableFiltersWithoutCurrent = tableFilters.filter(
      (filter) => !(filter.key === field && filter.op === FilterOperatorEnum.CONTAINS),
    );

    dispatch(searchActions.setTableFilters(tableFiltersWithoutCurrent));
    dispatch(searchActions.setIsElasticEnabled(true));
  };

  return (
    <div className="relative">
      <Tooltip title="Search">
        <IconButton
          disabled={disabled}
          onClick={onClick}
          className="mb-1 mr-1"
          size="small"
          data-testid="search-icon"
        >
          <Badge
            classes={{
              badge: 'pr-0 top-[5px] right-[9px]',
            }}
            badgeContent={<div className="rounded-full bg-[#0484E7] border border-white w-2 h-2" />}
            invisible={!defaultValue}
          >
            <IconSearch />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        id="tableHeaderSearch"
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: 'overflow-visible',
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 17, horizontal: 140 }}
      >
        <TextField
          size="small"
          className="w-[180px] border border-[#ddd] text-[13px]"
          placeholder="Search..."
          InputProps={{
            classes: {
              root: 'pl-1',
              notchedOutline: 'border-[#ddd]',
            },
            endAdornment: value ? (
              <IconButton
                className="w-5 h-5 ml-1"
                onClick={() => handleClear()}
              >
                <CloseIcon className="w-[14px]	h-[14px]" />
              </IconButton>
            ) : undefined,
            startAdornment: (
              <Tooltip title="Search">
                <IconButton
                  onClick={() => search()}
                  className="mr-1"
                  size="small"
                  data-testid="search-icon"
                >
                  <IconSearch />
                </IconButton>
              </Tooltip>
            ),
          }}
          inputProps={{
            style: { fontSize: 12 },
            autoComplete: 'off',
          }}
          value={value}
          onChange={(e) => setValue(e.target.value.trim())}
          onKeyDown={handleEnter}
        />
      </Popover>
    </div>
  );
};
