import React, { RefObject, useCallback, useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch } from 'react-redux';
import { isEmpty, isNil, uniq } from 'lodash';
import 'chart.js/auto';

import { downloadChartExcel, downloadChartImg } from './utils';
import {
  BarChart,
  LineChart,
  StackedBarChart,
  LineOverBarChart,
  SankeyChart,
  MapChart,
} from '@/Components/Shared/Charts';
import { ChartConfigType, ChartDatasetType, DatumDatum } from '@/types';
import { actions } from '@/slices/charts';
import { CompareChartsType } from '@/types/state/charts';
import { useQueriesChartCompany, useQueriesChartComprasion, useQueryChartFilteredData } from '@/hooks/queries';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { useQueryChartFilters } from '@/hooks/queries/charts/use-query-chart-filters';

export interface IChartCommonProps {
  data: ChartDatasetType;
  isLoading: boolean;
  section: string;
  params?: { row: DatumDatum };
  config: ChartConfigType;
  tabSlug: string;
}

const filterBySource = (
  data: ChartDatasetType,
  allSources: (string | number)[],
  preferredDataSource: string | null,
) => {
  if (!allSources.length) return data;

  return {
    bainId: data.bainId,
    data: data.data.filter(({ DATA_SOURCE }) =>
      isNil(preferredDataSource) ? false : DATA_SOURCE === preferredDataSource,
    ),
  };
};

export const ChartCommon = ({
  data: allData,
  params,
  section,
  isLoading: isFetching,
  config,
  tabSlug,
}: IChartCommonProps) => {
  const bainId = allData?.bainId;
  const dispatch = useDispatch();
  const { data: companies } = useQueriesChartCompany(bainId);
  const chartFiltersQuery = useQueryChartFilters(config.ID, bainId);
  const filteredDataQuery = useQueryChartFilteredData(config.ID, bainId);
  const comparisonQueries = useQueriesChartComprasion(bainId, section, config.ID);

  const type = config.CHART_TYPE;
  const label = config.TITLE;
  const currentDataSource = useShallowSelector((state) => state.companyProfile.genericTabDataSource?.[tabSlug]);
  const filters = useMemo(() => chartFiltersQuery.data?.filters ?? [], [chartFiltersQuery]);
  const allSourcesData = isEmpty(filteredDataQuery.data) ? allData : filteredDataQuery.data;

  const isComparisonDatasetsLoading = useMemo(
    () => comparisonQueries.some((query) => query.status === 'loading'),
    [comparisonQueries],
  );
  const allSourcesComparisonDatasets = useMemo(
    () => comparisonQueries.filter((item) => item.isFetched).map((item) => item.data ?? []),
    [comparisonQueries],
  ) as ChartDatasetType[];

  const isLoading = isFetching
    || filteredDataQuery.isFetching
    || filteredDataQuery.isFetching
    || isComparisonDatasetsLoading;

  const allSources = uniq(allSourcesData?.data.map((item) => item.DATA_SOURCE).filter((item) => !isNil(item)));
  const data = useMemo(
    () => filterBySource(allSourcesData, allSources, currentDataSource),
    [allSources, allSourcesData, currentDataSource],
  );
  const comparisonDatasets = useMemo(
    () => allSourcesComparisonDatasets.map((dataSet) => filterBySource(dataSet, allSources, currentDataSource)),
    [allSources, allSourcesComparisonDatasets, currentDataSource],
  );

  const handleDownloadXlsx = useCallback(async () => {
    const sheets = [
      {
        name: companies[data.bainId],
        data: data.data,
      },
      ...comparisonDatasets
        .filter((dataset) => dataset.data.length > 0)
        .map((dataset) => ({
          name: companies[dataset.bainId],
          data: dataset.data,
        })),
    ];

    await downloadChartExcel({
      fileName: `${label}-data.xlsx`,
      sheets,
    });
  }, [companies, comparisonDatasets, data, label]);

  const handleRemoveDataset = useCallback(
    (compareData: CompareChartsType) => {
      dispatch(actions.removeCompany(compareData));
    },
    [dispatch],
  );

  const handleDownloadImage = useCallback(
    (containerRef: RefObject<HTMLDivElement | null>) => {
      downloadChartImg(containerRef, `${label}-chart.png`);
    },
    [label],
  );

  if (isLoading) {
    return (
      <Skeleton
        height={400}
        variant="rounded"
        className="bg-[#0000000f] rounded-2xl"
        data-testid="skeleton"
      />
    );
  }

  if (isEmpty(data) && !isLoading) {
    return null;
  }

  return (
    <div
      className="bg-white rounded h-full"
      style={{ boxShadow: '0px 1px 3px 0px rgba(46, 48, 48, 0.14)' }}
    >
      {type === 'LINE' && (
        <LineChart
          config={config}
          data={data}
          params={params}
          section={section}
          comparisonDatasets={comparisonDatasets}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
          handleRemoveDataset={handleRemoveDataset}
        />
      )}

      {type === 'BAR' && (
        <BarChart
          config={config}
          data={data}
          params={params}
          section={section}
          comparisonDatasets={comparisonDatasets}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
          handleRemoveDataset={handleRemoveDataset}
        />
      )}

      {type === 'STACKED_BAR' && (
        <StackedBarChart
          config={config}
          data={data}
          filters={filters}
          params={params}
          section={section}
          comparisonDatasets={comparisonDatasets}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
        />
      )}

      {type === 'LineOverBar' && config && (
        <LineOverBarChart
          config={config}
          data={data}
          subType={config.METADATA?.CHART_SUBTYPE}
          comparisonDatasets={comparisonDatasets}
          handleDownloadXlsx={handleDownloadXlsx}
          handleDownloadImage={handleDownloadImage}
          handleRemoveDataset={handleRemoveDataset}
        />
      )}

      {type === 'MAP' && (
        <MapChart
          config={config}
          data={data}
        />
      )}

      {type === 'SANKEY' && (
        <SankeyChart
          config={config}
          data={data}
        />
      )}
    </div>
  );
};
