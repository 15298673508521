import React from 'react';
import { CreateListWorkflow } from '@/Components/Workflows/CreateListWorkflow';

const CreateListWorkflowPage = () => {
  return (
    <div className="w-full h-[calc(100vh-52px)] flex flex-col">
      <CreateListWorkflow />
    </div>
  );
};

export default CreateListWorkflowPage;
