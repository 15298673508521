import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

export const CaseCodeForm = ({
  inputs,
  form: {
    control,
    register,
    formState: { errors },
    handleSubmit,
  },
  onSubmit,
}) => {
  const handleEnter = (event) => {
    const isEnter = event.key === 'Enter';

    if (isEnter) {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return (
    <form className="flex flex-col text-black mt-5">
      {inputs.map(
        ({ key, label: inputLabel, validation, requiredValidationMessage, asTextArea, dataTestId, items, info }) => {
          const isError = !!errors[key];
          const getErrorMessage = () => {
            if (errors[key]?.type === 'required' && !!requiredValidationMessage) {
              return requiredValidationMessage;
            }

            return errors[key]?.type === 'required' ? 'Required field' : errors[key]?.message;
          };

          const errorLabel = <span className="text-[#d63333] text-sm pb-2 mt-[-8px]">{getErrorMessage()}</span>;

          if (items) {
            return (
              <>
                <Controller
                  key={key}
                  name={key}
                  control={control}
                  rules={validation}
                  render={({ field }) => (
                    <>
                      <InputLabel
                        htmlFor={key}
                        classes={{ root: 'text-[#484848] text-sm pb-4 font-medium' }}
                      >
                        {inputLabel}
                      </InputLabel>
                      {info ? <span className="text-[#484848] text-sm font-medium pb-2">{info}</span> : null}
                      <FormControl>
                        <Select
                          {...field}
                          id={key}
                          className="text-black mb-3"
                          MenuProps={{
                            classes: {
                              paper: 'bg-white text-black',
                            },
                          }}
                          error={isError}
                          size="small"
                          data-testid={key}
                        >
                          {items.map(({ value, label }) => (
                            <MenuItem
                              key={label}
                              value={value}
                            >
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                />
                {errorLabel}
              </>
            );
          }

          return (
            <React.Fragment key={key}>
              <InputLabel
                htmlFor={key}
                classes={{ root: 'text-[#484848] text-sm pb-2' }}
              >
                {inputLabel}
              </InputLabel>
              {info ? <span className="text-[#484848] text-sm font-medium pb-2">{info}</span> : null}
              <TextField
                multiline={asTextArea}
                minRows={asTextArea ? 6 : undefined}
                key={key}
                id={key}
                data-testid={dataTestId}
                {...register(key, {
                  ...validation,
                  setValueAs: (value) => value.trim(),
                })}
                classes={{ root: 'mt-2 mb-4' }}
                variant="outlined"
                InputProps={{
                  classes: {
                    root: 'text-black',
                  },
                }}
                error={isError}
                placeholder={validation?.required ? undefined : 'Optional'}
                size="small"
                onKeyPress={handleEnter}
              />
              {errorLabel}
            </React.Fragment>
          );
        },
      )}
    </form>
  );
};
