import React from 'react';
import { useSnackbar } from 'notistack';
import Skeleton from '@mui/material/Skeleton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { isNil, reduce, has } from 'lodash';
import { Tab as TabType } from '../types';
import { hasActivePeopleTab, isSentimentEnabled } from '../tabs/PeopleTab/utils';
import { CustomButton } from '../../Search/Buttons/CustomButton';
import { ProfileHeaderItem } from './HeaderItem';
import { HeaderChips } from './HeaderChips';
import ExportComponent from '@/Components/ExportComponent';
import { ShareButton } from '@/Components/Shared/Buttons/ShareButton';
import { DATA_SOURCE_ID, DATA_SOURCE_IDS, MODALS } from '@/constants';
import { copyToClipboard } from '@/Utils/navigator-utils';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { useModal } from '@/hooks/use-modal';
import { ConfidenceScoresSwitch } from '@/Components/Shared/Switch/ConfidenceScoresSwitch';
import { IconBusinessModel, IconEmployee, IconFoundationYear, IconLocation, IconWebPage } from '@/Components/Icons';
import { DatumDatum, ICompaniesListSnippet } from '@/types';
import { numberFormatterCustom } from '@/Components/Shared/common';
import { SourceLogo } from '@/Components/Shared/SourceLogo/SourceLogo';
import { GlassdoorLogo } from '@/Components/Shared/SourceLogo/GlassdoorLogo';

interface IProps {
  bainId: string;
  tabs: TabType[];
  companiesLists: ICompaniesListSnippet[];
  params?: {
    row: DatumDatum;
    value?: string;
  };
  showConfidenceScore: boolean;
  isCompanyProfileLoading: boolean;
  setShowConfidenceScore: React.Dispatch<React.SetStateAction<boolean>>;
  handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const TEXT = `
  The IDs below represent, in part, where information contained in this company profile is sourced.
  This company profile does not contain all data from each source.
  Data elements on this company profile may utilize one or many sources resulting in differences if the information is referenced in those sources directly.
`;

const extractFirstSentence = (text: string) => {
  const sentences = text.split(/\.\s+(?=[A-Z])/);

  return sentences[0] ? sentences[0] + '.' : '';
};

const getBusinessModel = (data?: DatumDatum) => {
  const value =
    data?.[data?.self_firmo_businessmodel___ ? 'self_firmo_businessmodel___' : 'SELF_FIRMO_BUSINESSMODEL__ZI'];

  if (Array.isArray(value)) {
    return `${value?.join(' | ')}`;
  }

  return `${value}`;
};

const getDataSources = (data?: DatumDatum) =>
  reduce(
    Object.entries(DATA_SOURCE_IDS),
    (acc, [field, label]) => {
      if (has(data, field)) {
        acc.push({ id: field as DATA_SOURCE_ID, label });
      }

      return acc;
    },
    [] as { id: DATA_SOURCE_ID; label: string }[],
  );

const getLocation = (data?: DatumDatum) => {
  const city = data?.self_location_city___;
  const country = data?.self_location_country___;

  if (!city) {
    return country ? country : 'undefined';
  }

  if (!country) {
    return 'undefined';
  }

  return `${city}, ${country}`;
};

export const CompanyProfileHeader = ({
  bainId,
  tabs,
  companiesLists,
  params,
  showConfidenceScore,
  isCompanyProfileLoading,
  setShowConfidenceScore,
  handleClick,
}: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleOpen: handleOpenNewCompaniesListDialog } = useModal(MODALS.CREATE_COMPANIES_LIST);
  const hasEmployeesData = !isNil(params?.row.self_firmo_employeeCount___);
  //TODO: refactor the way of checking if data exists
  const employees = hasEmployeesData
    ? `${numberFormatterCustom(params?.row.self_firmo_employeeCount___ ?? 0, 0)}`
    : String(undefined);
  const location = getLocation(params?.row);
  const yearFounded = `${params?.row.self_firmo_yearFounded___}`;
  const webpage = `${params?.row.self_firmo_webpage___}`;
  const businessModel = getBusinessModel(params?.row);
  const dataSources = getDataSources(params?.row);

  const handleShareButtonClick = () => {
    copyToClipboard(
      window.location.href,
      () =>
        enqueueSnackbar(<NotificationMessage title={'Link copied to clipboard'} />, {
          variant: 'success',
        }),
      () =>
        enqueueSnackbar(
          <NotificationMessage title={'Failed to copy link to clipboard. Please try again or contact support.'} />,
          {
            variant: 'error',
          },
        ),
    );
  };

  const handleOpenDialog = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    companiesLists.length > 0
      ? handleClick(event)
      : handleOpenNewCompaniesListDialog({ companyProfileSelectedCompanies: [bainId] });
  };

  return (
    <div className="py-10 px-6 flex justify-between items-start min-h-[68px]">
      {isCompanyProfileLoading ? (
        <Skeleton
          height={164}
          className="bg-[#0000000f] w-full"
          data-testid="skeleton"
        />
      ) : (
        <>
          <div className="flex items-center gap-10">
            {params?.row.self_logourl && (
              <div className="self-start min-w-[160px] min-h-[160px] bg-white shadow-[0_0_4px_0_rgba(0,0,0,0.25)] rounded p-3">
                <div
                  style={{ backgroundImage: `url(${params?.row.self_logourl})` }}
                  className="h-[136px] bg-no-repeat bg-contain bg-center"
                />
              </div>
            )}

            <div>
              <span
                id="company-profile"
                className="text-3xl font-medium text-[#484848] "
              >
                {params?.value}
              </span>

              {params?.row.self_firmo_description___ ? (
                <div className="pt-3 text-sm text-[#484848]">
                  <p>{extractFirstSentence(params?.row.self_firmo_description___ ?? '')}</p>
                </div>
              ) : null}

              <div className="flex flex-col">
                <div className="flex py-4 flex-wrap">
                  <ProfileHeaderItem
                    Icon={IconEmployee}
                    text={employees}
                    tooltipText={
                      hasEmployeesData ? params?.row.self_firmo_employeeCount___?.toLocaleString('en') : undefined
                    }
                    dataTestId="profile-header-item-employee-count"
                  />
                  <ProfileHeaderItem
                    Icon={IconLocation}
                    text={location}
                    dataTestId="profile-header-item-location"
                  />
                  <ProfileHeaderItem
                    Icon={IconFoundationYear}
                    text={yearFounded}
                    dataTestId="profile-header-item-year-founded"
                  />
                  <ProfileHeaderItem
                    Icon={IconBusinessModel}
                    text={businessModel}
                    dataTestId="profile-header-item-business-model"
                  />
                  <ProfileHeaderItem
                    Icon={IconWebPage}
                    text={webpage}
                    isHref
                    dataTestId="profile-header-item-webpage"
                  />
                </div>

                <HeaderChips row={params?.row} />
              </div>
            </div>
          </div>

          <div className="flex flex-col ml-10 gap-4 grow-0 shrink-0">
            <div className="flex gap-3 mb-3 justify-end">
              <ShareButton
                onClick={handleShareButtonClick}
                asCustomButton
              />

              <CustomButton onClick={handleOpenDialog}>Add to list</CustomButton>

              <ExportComponent companyProfileId={bainId} />
            </div>

            <div
              className="flex flex-col items-end"
              data-testid="data-sources-container"
            >
              <div className="flex items-center gap-2">
                <span className="text-sm text-[#484848]">Data sources</span>

                <Tooltip title={TEXT}>
                  <InfoOutlinedIcon className="text-lg" />
                </Tooltip>
              </div>

              <div className="flex gap-2 mt-2">
                {dataSources.map((item) => (
                  <SourceLogo
                    key={item.id}
                    title={item.label}
                    sourceId={params?.row[item.id as keyof DatumDatum] as string}
                    columnId={item.id}
                  />
                ))}
                {isSentimentEnabled(params) && hasActivePeopleTab(tabs) ? <GlassdoorLogo /> : null}
              </div>
            </div>
            <ConfidenceScoresSwitch
              onToggle={() => setShowConfidenceScore((prevState) => !prevState)}
              isChecked={showConfidenceScore}
            />
          </div>
        </>
      )}
    </div>
  );
};
